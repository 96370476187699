import { UserGateway } from '../../../corelogic/gateways/UserGateway.interface';
import { User } from '../../../domain/user/user';
import { APIService } from '../services/API.service';
import { APIGateway } from '../../../utils/abstracted/APIGateway';
import { ID } from '../../../domain/shared';

export class UserAPIGateway extends APIGateway implements UserGateway {
    constructor(apiService: APIService) {
        super('user', apiService);
    }

    async getUserByForeignId(userForeignId: string): Promise<{ user: User }> {
        return this.apiGet(userForeignId, 'foreign');
    }

    async getUserById(userId: string): Promise<{ user: User }> {
        return this.apiGet(userId);
    }

    async updateUser(userId: ID, user: User): Promise<void> {
        return this.apiPost({ user }, '', [userId]);
    }
}
