import { toast, TypeOptions } from 'react-toastify';
import { Env, getEnv, NodeEnv } from './env';

export type ToasterId = ReturnType<typeof toast.loading>;

export class Toaster {
    private static autoCloseDelay = getEnv(Env.NODE_ENV) === NodeEnv.PRODUCTION ? 3000 : 1000;
    constructor(private toasterId: ToasterId) {}

    static error(message: string) {
        toast.error(message, { autoClose: this.autoCloseDelay });
    }

    static success(message: string) {
        toast.success(message, { autoClose: this.autoCloseDelay });
    }

    static warn(message: string) {
        toast.warn(message, { autoClose: this.autoCloseDelay });
    }

    static loading(message: string) {
        const toastId: ToasterId = toast.loading(message);
        return new Toaster(toastId);
    }

    updateSuccess(message: string) {
        this.update(message, 'success');
    }

    updateError(message: string) {
        this.update(message, 'error');
    }

    updateWarning(message: string) {
        this.update(message, 'warning');
    }

    private update(message: string, type: TypeOptions, isLoading = false) {
        toast.update(this.toasterId, {
            render: message,
            autoClose: Toaster.autoCloseDelay,
            type,
            isLoading,
        });
    }
}
