import { APIService } from './API.service';
import { CatalogService } from './Catalog.service';

export class Services {
    private static _services: Services;
    private readonly _catalogService: CatalogService;
    private readonly _apiService: APIService;

    private constructor() {
        this._apiService = new APIService();
        this._catalogService = new CatalogService();
    }

    public static instance() {
        if (!this._services) {
            this._services = new Services();
        }
        return this._services;
    }

    public get api(): APIService { return this._apiService; }
    public get catalog(): CatalogService { return this._catalogService; }
}
