import { Example } from '../../../../../domain/practice/example';

export function useExampleWidget() {
    const computeHeight = (example: Example): string => {
        const firstLineOffset = 5;
        const oneLineHeight = 19;
        const lineCount = example.position.end.line - example.position.start.line + 1;
        const visibleHeight = lineCount * oneLineHeight;
        return `${visibleHeight + firstLineOffset}px`;
    };

    return { computeHeight };
}
