import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Modal } from '@mui/material';

import './practiceModal.scss';
import { usePracticeModal } from './practiceModal.logic';

import ICategories from '../../categories/categories';

import { Practice } from '../../../../../domain/practice/practice';
import { openCatalog } from '../../../../../corelogic/usecases/catalog/viewCatalog/openCatalog';
import { ChatBubbleOutline, Close } from '@mui/icons-material';
import { IExampleInformation } from './exampleInformation/exampleInformation';
import { IPracticeRating } from './practiceRating/practiceRating';
import { IPracticeComments } from './practiceComments/practiceComments';
import { IPracticeSwitchingButtons } from './practiceSwitchingButtons/practiceSwitchingButtons';
import MarkdownViewer from '../../../components/shared/markdownViewer/markdownViewer';

type IPracticeModalProps = {
    practice?: Practice;
    isOpen: boolean;
    closeModal: () => void;
};

export function IPracticeModal({ practice, isOpen, closeModal }: IPracticeModalProps) {
    if (!practice) return <div />;
    const { t } = useTranslation();
    const {
        catalog,
        isLoadingPractice,
        practiceFull,
        getFullPracticeEffect,
        shouldDisplayGoToCatalog,
        copyLinkToPractice,
    } = usePracticeModal(practice);

    useEffect(() => {
        if (isOpen) {
            getFullPracticeEffect();
        }
    }, [isOpen, practice]);

    const [commentNumber, setCommentNumber] = useState(0);

    return (
        <Modal open={isOpen} onClose={closeModal} className="modal">
            <section className="practice-modal-content">
                {catalog ? <IPracticeSwitchingButtons catalog={catalog} practice={practice} /> : null}
                <div className="overflow-container">
                    <IconButton className="close-modal-button" onClick={closeModal}>
                        <Close />
                    </IconButton>

                    <div className="practice-information">
                        <h1 className="practice-title">{practice.name}</h1>
                        <ICategories categories={practice.categories} />
                        <div className="rating-comment-number-container">
                            <IPracticeRating practice={practice} />
                            <div className="comment-icon-container">
                                <ChatBubbleOutline fontSize={'medium'} color={'secondary'} />
                            </div>
                            <div className="comment-number">{commentNumber}</div>
                        </div>
                        <div className="practice-action-button-container">
                            {shouldDisplayGoToCatalog() && catalog ? (
                                <div className="to-catalog-button-container">
                                    <button className="hub-button" type="button" onClick={() => openCatalog(catalog)}>
                                        {t('TO_CATALOG_BUTTON')}
                                    </button>
                                </div>
                            ) : null}
                            <button className="hub-button" type="button" onClick={copyLinkToPractice}>
                                {t('COPY_LINK_TO_PRACTICE_BUTTON')}
                            </button>
                        </div>

                        {!isLoadingPractice && practiceFull ? (
                            <div className="practice-description">
                                <div
                                    className="practice-description-text"
                                >
                                    <MarkdownViewer theme={'light-theme'} rawContent={practiceFull.description} />
                                </div>
                            </div>
                        ) : (
                            <div className="practice-loading">
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                    {!isLoadingPractice && practiceFull ? (
                        <IExampleInformation practice={practiceFull} isLoadingPractice={isLoadingPractice} />
                    ) : null}
                    {!isLoadingPractice && practiceFull ? (
                        <IPracticeComments practice={practiceFull} setCommentNumber={setCommentNumber} />
                    ) : null}
                </div>
            </section>
        </Modal>
    );
}
