import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, categoriesGateway } from './baseThunkInfo';
import { CategoryState } from '../category.state';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const getAllCategories = createAsyncThunk(`${baseName}/getAllCategories`, () => {
    return categoriesGateway.getCategories();
});

export const buildGetAllCategoriesReducers = (builder: ActionReducerMapBuilder<CategoryState>) => {
    builder.addCase(getAllCategories.pending, state => {
        state.isFetching = true;
    });

    builder.addCase(getAllCategories.fulfilled, (state, action) => {
        state.categoryList = action.payload;
        state.isFetching = false;
    });

    builder.addCase(getAllCategories.rejected, state => {
        state.isFetching = false;
        Toaster.error(translate('GET_ALL_CATEGORIES_FAILURE'));
    });
};
