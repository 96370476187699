import { Example } from './example';
import { ID } from '../shared';
import { Rating } from './rating';

export type Practice = {
    _id: ID;
    name: string;
    categories: string[];
    rating: Rating;
};

export type PracticeSearched = Practice & { isSearched: boolean };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPracticeSearchedType = (practice: any): practice is PracticeSearched =>
    practice && practice.isSearched !== undefined;

export type PracticeFull = Practice & {
    description: string;
    examples: Example[];
    extensions: string[];
};

export type PracticeImport = {
    name: string;
    description: string;
    categories: string[];
    extensions: string[];
};

export type PracticeLike = PracticeFull | ID;
