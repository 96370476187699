import { Practice, PracticeFull } from '../../../domain/practice/practice';

import { PracticeGateway } from '../../../corelogic/gateways/PracticeGateway.interface';
import { ID } from '../../../domain/shared';

export class PracticeInMemoryGateway implements PracticeGateway {
    getPracticeDetails(): Promise<PracticeFull> {
        return Promise.resolve({
            _id: '1',
            name: 'Practice 1',
            description: 'Practice 1 description',
            categories: [],
            examples: [],
            extensions: [],
            rating: { likes: [], dislikes: [] },
        });
    }

    search(): Promise<{ practices: Practice[]; hasMore: boolean; totalSearchNumber: number }> {
        return Promise.resolve({ practices: [], hasMore: false, totalSearchNumber: 0 });
    }

    async dislikeAction(catalogId: ID, userId: ID): Promise<void> {
        void userId;
    }

    async likeAction(catalogId: ID, userId: ID): Promise<void> {
        void userId;
    }
}
