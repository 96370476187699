import React, { useEffect, useState } from 'react';

import './categories.scss';

import { Category } from '../../../../domain/category/category';

export type ICategoriesProps = {
    categories: Category[];
};

function ICategories({ categories }: ICategoriesProps) {
    const MAX_CATEGORIES_TO_DISPLAY = 3;
    const [categoriesFormatted, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        formatCategories();
    }, [categories]);

    const formatCategories = () => {
        const categoriesSorted = [...categories].sort((a, b) => a.localeCompare(b));
        const categoriesSelected = categoriesSorted.slice(0, MAX_CATEGORIES_TO_DISPLAY);
        if (categoriesSorted.length > MAX_CATEGORIES_TO_DISPLAY) {
            const categoriesRestCount = categoriesSorted.length - MAX_CATEGORIES_TO_DISPLAY;
            categoriesSelected.push(`+${categoriesRestCount}`);
        }
        setCategories(categoriesSelected);
    };

    return (
        <div className='categories'>
            {categoriesFormatted.map((category, index) => (
                <div className='category-gradient' key={index}>
                    <p className='category' title={category}>
                        <span className={'category-text'}>{category}</span></p>
                </div>
            ))}
            {categoriesFormatted.length === 0 && <div className={'ghost-category'}/>}
        </div>
    );
}

export default ICategories;
