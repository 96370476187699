import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import { NavigationService } from '../../adapters/secondary/services/Navigation.service';
import { NavigationInterface } from '../../adapters/secondary/services/NavigationInterface';

import { cartReducer } from './slices/cart/cart.slice';
import { catalogReducer } from './slices/catalog/catalog.slice';
import { categoryReducer } from './slices/category/category.slice';
import { listReducer } from './slices/list/list.slice';
import { userReducer } from './slices/user/user.slice';
import { practiceReducer } from './slices/practice/practice.slice';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        catalog: catalogReducer,
        practice: practiceReducer,
        category: categoryReducer,
        list: listReducer,
        user: userReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export class DependenciesGatherer {
    private static instance: DependenciesGatherer;

    public static getInstance(): DependenciesGatherer {
        if (!this.instance) {
            this.instance = new DependenciesGatherer(store);
        }
        return this.instance;
    }

    public static instantiateServices(navigation: NavigationInterface = new NavigationService()) {
        this.instance.navigationService = navigation;
    }

    private constructor(private _store: EnhancedStore, private _navigationService?: NavigationInterface) {}

    public get store(): EnhancedStore {
        return this._store;
    }
    public set store(store: EnhancedStore) {
        this._store = store;
    }

    public get navigationService(): NavigationInterface {
        if (!this._navigationService) {
            throw new Error('Navigation not instantiated');
        }
        return this._navigationService;
    }
    public set navigationService(navigationService: NavigationInterface) {
        this._navigationService = navigationService;
    }
}

export function getDependencies(): DependenciesGatherer {
    return DependenciesGatherer.getInstance();
}

export function getStore() {
    return getDependencies().store;
}

export function getState(): RootState {
    return getDependencies().store.getState();
}

export const getDispatch: () => AppDispatch = () => getStore().dispatch;
