import { UserCartGateway } from '../../../corelogic/gateways/UserCartGateway.interface';
import { ID } from '../../../domain/shared';
import { Cart } from '../../../domain/cart/cart';
import { APIService } from '../services/API.service';
import { APIGateway } from '../../../utils/abstracted/APIGateway';

export class UserCartAPIGateway extends APIGateway implements UserCartGateway {
    constructor(api: APIService) {
        super('user-cart', api);
    }

    async getCartForUser(userId: ID): Promise<Cart> {
        return await this.apiGet(userId);
    }

    async setCartForUser(cart: Cart, userId: ID): Promise<void> {
        await this.apiPost({ userCart: { ...cart, userId } });
    }
}
