import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { Category } from '../../../../domain/category/category';

import {
    isCategorySelected,
    selectCategory,
    unselectCategory,
} from '../../../../corelogic/usecases/category/categorySelection/categorySelection';
import { searchCatalogs } from '../../../../corelogic/usecases/catalog/searchCatalogs/searchCatalogs';
import { searchPractices } from '../../../../corelogic/usecases/practice/searchPractices/searchPractices';
import { setSearchText } from '../../../../corelogic/usecases/search/setSearchText/setSearchText';

import { getCategoryList, isFetching } from '../../../../corelogic/redux/slices/category/category.selector';
import { getSearchText } from '../../../../corelogic/redux/slices/list/list.selector';

let timeout: NodeJS.Timeout;
export function useFilters() {
    const categoryList = useSelector(getCategoryList);
    const isFetchingCategories = useSelector(isFetching);
    const search = useSelector(getSearchText);
    const [lastSearchText, setLastSearchText] = useState('');

    const handleOnSearchTextChanged = async ({ target: { value: textToSearch } }: ChangeEvent<HTMLInputElement>) => {
        setSearchText(textToSearch);

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (textToSearch !== lastSearchText) {
                searchPractices();
                searchCatalogs();
                setLastSearchText(textToSearch);
            }
        }, 500);
    };

    const handleOnCategoryClicked = async (category: Category, isChecked: boolean) => {
        if (isChecked) {
            await selectCategory(category);
        } else {
            await unselectCategory(category);
        }

        searchCatalogs();
        searchPractices();
    };

    const isSelected = (category: Category) => {
        return isCategorySelected(category);
    };

    return {
        categoryList,
        isFetchingCategories,
        search,
        handleOnCategoryClicked,
        handleOnSearchTextChanged,
        isSelected,
    };
}
