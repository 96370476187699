import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import french from './translations/fr.json';
import english from './translations/en.json';

const resources = {
    en: {
        translation: english,
    },
    fr: {
        translation: french,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export const translate = i18n.t;

export default i18n;
