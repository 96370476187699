import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@mui/material';

import './catalogList.scss';
import { useFilters } from './catalogList.logic';

import ICatalogCard from '../../cards/catalogCard/catalogCard';

export function ICatalogList() {
    const { t } = useTranslation();
    const { catalogList, hasMoreCatalogs, isFetchingCatalogs, handleLoadMore } = useFilters();

    return (
        <section className="result-container">
            {isFetchingCatalogs &&
                <article className="loader-container">
                    <CircularProgress />
                </article>
            }

            {!isFetchingCatalogs && catalogList.length === 0
                ? <div className="no-result">{t('NO_RESULT_CATALOGS')}</div>
                : null
            }

            <InfiniteScroll
                className="result-list"
                loadMore={handleLoadMore}
                hasMore={hasMoreCatalogs}
                initialLoad={false}
                loader={
                    <article key="more-catalog-loader" className="load-more-loading-container">
                        <CircularProgress className="load-more-loading" />
                    </article>
                }
                useWindow={false}
                getScrollParent={() => document.getElementById('scroll-parent')}
            >
                {catalogList.map(catalog => (
                    <ICatalogCard key={catalog._id} catalog={catalog} />
                ))}
            </InfiniteScroll>
        </section>
    );
}
