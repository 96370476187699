import { CategoryGateway } from '../../../corelogic/gateways/CategoryGateway.interface';

import { Category } from '../../../domain/category/category';

export class CategoryInMemoryGateway implements CategoryGateway {
    public async getCategories(): Promise<{ category: Category, occurrence: number }[]> {
        return [
            { category: 'JS', occurrence: 1 },
            { category: 'React', occurrence: 1 },
            { category: 'TS', occurrence: 1 },
        ];
    }
}
