import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, IconButton } from '@mui/material';
import { Done } from '@mui/icons-material';

import { useAuthInfo } from '@propelauth/react';

import './AddToCartButton.scss';
import { useAddToCartLogic } from './AddToCartButton.logic';

import { Catalog } from '../../../../../domain/catalog/catalog';
import { Practice } from '../../../../../domain/practice/practice';

import { cartContains } from '../../../../../corelogic/usecases/cart/cartContains/cartContains';
import { getCart } from '../../../../../corelogic/redux/slices/cart/cart.selector';
import { INeedLoginModal } from '../../modals/needLoginModal/needLoginModal';

type IAddToCartButtonProps = {
    element: Catalog | Practice;
};

function IAddToCartButton(props: IAddToCartButtonProps) {
    const { addElementToCart, removeElementFromCart } = useAddToCartLogic();
    
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;
    
    useSelector(getCart);
    const [isNeedLoginModalOpen, setIsNeedLoginModalOpen] = useState(false);
    return !isAuthenticated ? (
        <div>
            <IconButton type="button" onClick={() => setIsNeedLoginModalOpen(true)} className="add-to-cart-button">
                <Badge badgeContent={'+'} color={'primary'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <img src="/images/library.svg" alt="" />
                </Badge>
            </IconButton>
            <INeedLoginModal isOpen={isNeedLoginModalOpen} setOpen={setIsNeedLoginModalOpen} />
        </div>
    ) : !cartContains(props.element) ? (
        <IconButton type="button" onClick={() => addElementToCart(props.element)} className="add-to-cart-button">
            <Badge badgeContent={'+'} color={'primary'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <img src="/images/library.svg" alt="" />
            </Badge>
        </IconButton>
    ) : (
        <IconButton type="button" onClick={() => removeElementFromCart(props.element)} className="add-to-cart-button">
            <Badge
                badgeContent={<Done className="badge-added" />}
                color={'success'}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <img src="/images/library.svg" alt="" />
            </Badge>
        </IconButton>
    );
}

export default IAddToCartButton;
