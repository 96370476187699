import React from 'react';

import './exampleWidget.scss';
import { useExampleWidget } from './exampleWidget.logic';

import { Example } from '../../../../../domain/practice/example';
import { Practice } from '../../../../../domain/practice/practice';

type IExampleWidgetProps = {
    example: Example;
    practice: Practice;
};

export const IExampleWidget: React.FC<IExampleWidgetProps> = ({ example, practice }) => {
    const { computeHeight } = useExampleWidget();
    const height = computeHeight(example);

    return (
        <div className={`example-widget ${example.isPositive ? 'positive' : 'negative'}`}>
            <div className="practice-name">{practice.name}</div>
            <div
                className={`practice-body-background ${example.isPositive ? 'positive' : 'negative'}`}
                style={{ height }}
            />
        </div>
    );
};
