import { useSelector } from 'react-redux';

import {
    getCatalogList,
    getHasMoreCatalogs,
    getIsFetchingCatalogs,
} from '../../../../../corelogic/redux/slices/list/list.selector';

import { loadMoreCatalogs } from '../../../../../corelogic/usecases/catalog/loadMoreCatalogs/loadMoreCatalogs';

export function useFilters() {
    const catalogList = useSelector(getCatalogList);
    const hasMoreCatalogs = useSelector(getHasMoreCatalogs);
    const isFetchingCatalogs = useSelector(getIsFetchingCatalogs);

    const handleLoadMore = loadMoreCatalogs;

    return { catalogList, hasMoreCatalogs, isFetchingCatalogs, handleLoadMore };
}
