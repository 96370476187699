import { getState } from '../../../redux/store';

import { Cart } from '../../../../domain/cart/cart';
import { GATEWAYS } from '../../../gateways/gatewaysUtils';

const userCartGateway = GATEWAYS.getUserCartGateway();

export async function setCartInDatabase() {
    const state = getState();
    const cart: Cart = { catalogList: state.cart.value.catalogList, practiceList: state.cart.value.practiceList };
    const userId = state.user.user?._id;
    if (userId) {
        await userCartGateway.setCartForUser(cart, userId);
    }
}
