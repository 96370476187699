import 'codemirror';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/coffeescript/coffeescript.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/dockerfile/dockerfile.js';
import 'codemirror/mode/groovy/groovy.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/jsx/jsx.js';
import 'codemirror/mode/htmlembedded/htmlembedded.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/markdown/markdown.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/mode/puppet/puppet.js';
import 'codemirror/mode/python/python.js';
import 'codemirror/mode/ruby/ruby.js';
import 'codemirror/mode/sass/sass.js';
import 'codemirror/mode/shell/shell.js';
import 'codemirror/mode/sql/sql.js';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/mode/swift/swift.js';
import 'codemirror/mode/vue/vue.js';
import 'codemirror/mode/rust/rust.js';
import 'codemirror/mode/clojure/clojure';
import 'codemirror/mode/mllike/mllike';
import 'codemirror/mode/go/go';
import 'codemirror/mode/haskell/haskell';
import 'codemirror/mode/julia/julia';
import 'codemirror/mode/commonlisp/commonlisp';
import 'codemirror/mode/perl/perl';
import 'codemirror/mode/r/r';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/lib/codemirror.css';

import { Example } from '../domain/practice/example';

const modes: { [key: string]: string } = {
    coffee: 'text/x-coffeescript',
    dockerfile: 'text/x-dockerfile',
    ts: 'text/typescript',
    tsx: 'text/typescript',
    vue: 'text/x-vue',
    js: 'text/javascript',
    jsx: 'text/jsx',
    html: 'text/html',
    htm: 'text/html',
    php: 'text/x-php',
    json: 'application/json',
    java: 'text/x-java',
    c: 'text/x-csrc',
    h: 'text/x-csrc',
    cpp: 'text/x-c++src',
    cxx: 'text/x-c++src',
    hpp: 'text/x-c++src',
    hxx: 'text/x-c++src',
    md: 'text/x-markdown',
    css: 'text/css',
    scss: 'text/x-scss',
    sass: 'text/x-scss',
    cs: 'text/x-csharp',
    py: 'text/x-python',
    rb: 'text/x-ruby',
    bash: 'text/x-sh',
    sh: 'text/x-sh',
    ksh: 'text/x-sh',
    sql: 'text/x-sql',
    swift: 'text/x-swift',
    yml: 'text/x-yaml',
    yaml: 'text/x-yaml',
    pp: 'text/x-puppet',
    kt: 'text/x-kotlin',
    text: 'text',
    groovy: 'text/x-groovy',
    jsp: 'application/x-jsp',
    scala: 'text/x-scala',
    sc: 'text/x-scala',
    rust: 'text/x-rustsrc',
    clj: 'text/x-clojure',
    fs: 'text/x-fsharp',
    fsi: 'text/x-fsharp',
    fsx: 'text/x-fsharp',
    fsscript: 'text/x-fsharp',
    go: 'text/x-go',
    hs: 'text/x-haskell',
    lhs: 'text/x-haskell',
    jl: 'text/x-julia',
    lisp: 'text/x-common-lisp',
    lsp: 'text/x-common-lisp',
    m: 'text/x-objectivec',
    mm: 'text/x-objectivec',
    mli: 'text/x-ocaml',
    ml: 'text/x-ocaml',
    pl: 'text/x-perl',
    r: 'text/x-rsrc',
    xml: 'application/xml',
};

export function getMode({ path }: Example): string {
    const extension = path.split('.').pop();

    if (extension && modes[extension]) {
        return modes[extension];
    } else {
        return modes.text;
    }
}
