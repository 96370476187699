import { CategoryGateway } from '../../../corelogic/gateways/CategoryGateway.interface';

import { Category } from '../../../domain/category/category';

import { APIService } from '../services/API.service';
import { APIGateway } from '../../../utils/abstracted/APIGateway';

export class CategoryAPIGateway extends APIGateway implements CategoryGateway {
    constructor(api: APIService) {
        super('category', api);
    }

    public async getCategories(): Promise<{ category: Category; occurrence: number }[]> {
        return this.apiGet();
    }
}
