import { CatalogGateway } from '../../../corelogic/gateways/CatalogGateway.interface';

import { APIService } from '../services/API.service';

import { Catalog, CatalogForSearch, CatalogImport, SearchCatalogs } from '../../../domain/catalog/catalog';
import { ID } from '../../../domain/shared';
import { APIGateway } from '../../../utils/abstracted/APIGateway';

export class CatalogAPIGateway extends APIGateway implements CatalogGateway {
    constructor(api: APIService) {
        super('catalog', api);
    }

    // eslint-disable-next-line
    importCatalog(catalogToImport: CatalogImport, owner: ID): Promise<CatalogForSearch> {
        return this.apiPost({ catalog: catalogToImport, userId: owner }, 'import');
    }

    exportPersonalisedCatalogWithPracticeIds(practiceIds: string[], userId: ID): Promise<object> {
        return this.apiPost({ practiceIds, userId }, 'export/personalised');
    }

    exportCatalogById(catalogId: string, userId: ID): Promise<object> {
        return this.apiService.get(`${this.baseURL}/export/${catalogId}/${userId}`);
    }

    search(search: string, categories: string[], page: number, pageSize: number): Promise<SearchCatalogs> {
        return this.apiPost({ search, categories }, 'search', [pageSize.toString(), page.toString()]);
    }

    deleteCatalog(catalogId: string, userId: ID): Promise<void> {
        return this.apiService.delete(`${this.baseURL}/${catalogId}/${userId}`);
    }

    getCatalogsForUser(userId: ID): Promise<Catalog[]> {
        return this.apiGet(userId, 'for-user');
    }

    getCatalogById(catalogId: ID): Promise<Catalog> {
        return this.apiGet(catalogId);
    }

    getCatalogFromPracticeId(practiceId: ID): Promise<Catalog> {
        return this.apiGet(['for-practice', practiceId]);
    }
}
