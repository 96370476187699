import { useSelector } from 'react-redux';

import { Practice } from '../../../../domain/practice/practice';
import { Catalog } from '../../../../domain/catalog/catalog';

import { emptyCart } from '../../../../corelogic/usecases/cart/removeFromCart/removeFromCart';
import {
    exportPersonalisedCatalogWithPractices,
    exportCatalog,
} from '../../../../corelogic/usecases/catalog/exportCatalog/exportCatalog';

import { getCart } from '../../../../corelogic/redux/slices/cart/cart.selector';

type CartLogic = {
    emptyCart: typeof emptyCart;
    practiceList: Practice[];
    catalogList: Catalog[];
    exportSelectedCatalogsAndPractices: () => void;
};

export function useCartLogic(): CartLogic {
    const { catalogList, practiceList } = useSelector(getCart);

    const exportSelectedCatalogsAndPractices = () => {
        catalogList.map((catalog) => exportCatalog(catalog));
        if (practiceList.length > 0) {
            exportPersonalisedCatalogWithPractices(practiceList);
        }
        emptyCart().then();
    };

    return {
        catalogList,
        practiceList,
        emptyCart,
        exportSelectedCatalogsAndPractices,
    };
}
