import { RootState } from '../../store';

export const getCatalogList = (state: RootState) => state.list.catalogs;
export const getPracticeList = (state: RootState) => state.list.practices;

export const getSearchText = (state: RootState) => state.list.search;

export const getIsFetchingCatalogs = (state: RootState) => state.list.isFetchingCatalogs;
export const getIsFetchingPractices = (state: RootState) => state.list.isFetchingPractices;

export const getHasMoreCatalogs = (state: RootState) => state.list.hasMoreCatalogs;
export const getHasMorePractices = (state: RootState) => state.list.hasMorePractices;

export const getCatalogsTotalSearch = (state: RootState) => state.list.catalogsTotalSearch;
export const getPracticesTotalSearch = (state: RootState) => state.list.practicesTotalSearch;
