import React from 'react';
import { useTranslation } from 'react-i18next';
import { useImportPage } from './importPage.logic';
import Dropzone from 'react-dropzone';
import './importPage.scss';
import { IconButton } from '@mui/material';
import { Delete, Download } from '@mui/icons-material';

export function IImportPage() {
    const { t } = useTranslation();
    const { submitFile: onSubmitFile, setFiles, removeFile, files } = useImportPage();
    return (
        <div className="main-page-container">
            <header className="page-header">
                <h1 className="page-title">{t('IMPORT_PAGE_TITLE')}</h1>
            </header>
            <Dropzone onDrop={setFiles}>
                {({ getRootProps, getInputProps }) => (
                    <section className={'drag-n-drop-container'}>
                        <div {...getRootProps()} className="drag-n-drop-area">
                            <input {...getInputProps()} />
                            <Download color={'primary'} className="upload-file-icon" />
                            <h3 className="drag-n-drop-title">{t('IMPORT_PAGE_DRAG_N_DROP_TITLE')}</h3>
                        </div>
                        <ul className="file-list">
                            {files.map((file, index) => (
                                <li key={index} className="file">
                                    {file.name}
                                    <IconButton onClick={() => removeFile(file)}>
                                        <Delete color={'error'} />
                                    </IconButton>
                                </li>
                            ))}
                        </ul>
                    </section>
                )}
            </Dropzone>
            <section className="action-buttons-container">
                <button className="hub-button import-button" type="button" onClick={onSubmitFile}>
                    {t('IMPORT_BUTTON_TEXT')}
                </button>
            </section>
        </div>
    );
}
