import { PracticeGateway } from '../../../corelogic/gateways/PracticeGateway.interface';

import { APIService } from '../services/API.service';

import { Category } from '../../../domain/category/category';
import { Practice, PracticeFull } from '../../../domain/practice/practice';
import { APIGateway } from '../../../utils/abstracted/APIGateway';
import { ID } from '../../../domain/shared';

export class PracticeAPIGateway extends APIGateway implements PracticeGateway {
    constructor(api: APIService) {
        super('practice', api);
    }

    getPracticeDetails(practiceId: string): Promise<PracticeFull> {
        return this.apiGet(practiceId, 'details');
    }

    search(
        search: string,
        categories: Category[],
        page: number,
        pageSize: number,
    ): Promise<{ practices: Practice[]; hasMore: boolean; totalSearchNumber: number }> {
        return this.apiPost({ search, categories }, 'search', [pageSize.toString(), page.toString()]);
    }

    async dislikeAction(practiceId: ID, userId: ID): Promise<void> {
        return this.apiPost({ practiceId, userId }, 'dislike');
    }

    async likeAction(practiceId: ID, userId: ID): Promise<void> {
        return this.apiPost({ practiceId, userId }, 'like');
    }
}
