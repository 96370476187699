import { UserGateway } from '../../../corelogic/gateways/UserGateway.interface';
import { User } from '../../../domain/user/user';
import { ID } from '../../../domain/shared';

export class UserInMemoryGateway implements UserGateway {
    public userList: User[] = [
        {
            _id: '1',
            name: 'louis',
            email: 'louis.demoulins@promyze.com',
            foreignId: '0d885b79-c85b-4836-a2f5-fb1a4688f2db',
        },
    ];

    async getUserByForeignId(userForeignId: string): Promise<{ user: User }> {
        const foundUser = this.userList.find(({ foreignId }) => foreignId === userForeignId);
        if (!foundUser) {
            throw new Error('Not found');
        }
        return { user: foundUser };
    }

    async getUserById(userId: string): Promise<{ user: User }> {
        const foundUser = this.userList.find(({ _id }) => _id === userId);
        if (!foundUser) {
            throw new Error('Not found');
        }
        return { user: foundUser };
    }

    async updateUser(userId: ID, user: User): Promise<void> {
        void user;
        return;
    }
}
