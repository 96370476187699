import { importCatalog } from '../../../../corelogic/usecases/catalog/importCatalog/importCatalog';
import { useState } from 'react';
import { getDependencies } from '../../../../corelogic/redux/store';

export function useImportPage() {
    const [files, setFiles] = useState<File[]>([]);
    const submitFile = async () => {
        if (files.length === 0) {
            return;
        }
        let numberOfFailures = 0;
        await Promise.all(
            files.map(async (file) => {
                const content = JSON.parse(await file.text());
                try {
                    await importCatalog(content);
                    removeFile(file);
                } catch {
                    numberOfFailures++;
                }
            }),
        );

        if (numberOfFailures === 0) {
            getDependencies().navigationService.navigate('/list');
        }
    };

    const removeFile = (file: File) => {
        setFiles(files.filter((f) => f !== file));
    };

    return {
        submitFile,
        setFiles,
        removeFile,
        files,
    };
}
