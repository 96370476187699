import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@mui/material';

import './practiceList.scss';
import { useFilters } from './practiceList.logic';

import IPracticeCard from '../../cards/practiceCard/practiceCard';

export function IPracticeList() {
    const { t } = useTranslation();
    const { practiceList, hasMorePractices, isFetchingPractices, handleLoadMore } = useFilters();

    return (
        <section className="result-container">
            {isFetchingPractices &&
                <article className="loader-container">
                    <CircularProgress />
                </article>
            }

            {!isFetchingPractices && practiceList.length === 0
                ? <div className="no-result">{t('NO_RESULT_PRACTICES')}</div>
                : null
            }

            <InfiniteScroll
                className="result-list"
                loadMore={handleLoadMore}
                hasMore={hasMorePractices}
                initialLoad={false}
                loader={
                    <article key="more-practice-loader" className="load-more-loading-container">
                        <CircularProgress className="load-more-loading" />
                    </article>
                }
                useWindow={false}
                getScrollParent={() => document.getElementById('scroll-parent')}
            >
                {practiceList.map((practice, index) => (
                    <IPracticeCard key={`${practice._id}-${index}`} practice={practice} />
                ))}
            </InfiniteScroll>
        </section>
    );
}
