import { ID } from '../shared';

export type Owner = {
    id: ID;
    name: string;
};

export function isSocialPlatform(platform: unknown): platform is SocialPlatforms {
    if (typeof platform !== 'string') {
        return false;
    }
    const platformList = Object.keys(SocialPlatforms);
    return !!platformList.find((platformElement) => platformElement === platform);
}

export enum SocialPlatforms {
    github = 'github',
    gitlab = 'gitlab',
    linkedin = 'linkedin',
    twitter = 'twitter',
}

export type SocialLink = {
    platform: SocialPlatforms;
    link: string;
};

export type User = {
    _id: string;
    name: string;
    email: string;
    foreignId: string;
    details?: string;
    socialLinks?: SocialLink[];
    categories?: string[];
};

export function cloneUser(userToClone: User): User {
    return {
        _id: userToClone._id,
        name: userToClone.name,
        email: userToClone.email,
        foreignId: userToClone.foreignId,
        details: userToClone.details ?? '',
        categories: userToClone.categories ? [...userToClone.categories] : [],
        socialLinks: userToClone.socialLinks ? [...userToClone.socialLinks] : [],
    };
}
