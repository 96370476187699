import React, { useEffect } from 'react';
import './practiceComments.scss';
import { Practice } from '../../../../../../domain/practice/practice';
import { IPracticeComment } from './practiceComment/practiceComment';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { INeedLoginModal } from '../../needLoginModal/needLoginModal';
import { usePracticeComments } from './practiceComments.logic';

type IPracticeCommentsProps = {
    practice: Practice;
    setCommentNumber: (commentNumber: number) => void;
};

export function IPracticeComments({ practice, setCommentNumber }: IPracticeCommentsProps) {
    const { t } = useTranslation();
    const {
        commentState,
        isCommentAreaOpen,
        openCommentArea,
        setIsCommentAreaOpen,
        addComment,
        isOpen,
        setIsOpen,
        newCommentRef,
        onPracticeChangeEffect,
        deleteComment,
    } = usePracticeComments([], practice._id, setCommentNumber);

    useEffect(onPracticeChangeEffect, [practice]);

    return (
        <div className="comment-list-container">
            <div className="new-comment-title">
                <h4>{t('WRITE_NEW_COMMENT')} </h4>
                {!isCommentAreaOpen ? (
                    <IconButton type="button" onClick={openCommentArea}>
                        <AddCircle color={'primary'} />
                    </IconButton>
                ) : null}
            </div>
            {isCommentAreaOpen ? (
                <div className="new-comment-container">
                    <textarea
                        className="new-comment-content"
                        ref={newCommentRef}
                        placeholder={t('WRITE_NEW_COMMENT')}
                    />
                    <div className="action-button-container">
                        <button className="hub-button success" type="button" onClick={addComment}>
                            {t('VALIDATE_BUTTON')}
                        </button>
                        <button className="hub-button error" type="button" onClick={() => setIsCommentAreaOpen(false)}>
                            {t('CANCEL_BUTTON')}
                        </button>
                    </div>
                </div>
            ) : null}

            {commentState.map((comment, index) => (
                <IPracticeComment comment={comment} key={index} deleteComment={deleteComment} />
            ))}

            <INeedLoginModal isOpen={isOpen} setOpen={setIsOpen} />
        </div>
    );
}
