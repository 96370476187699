import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ID } from '../../../../domain/shared';
import { User } from '../../../../domain/user/user';
import { GATEWAYS } from '../../../gateways/gatewaysUtils';
import { initialState } from './user.state';

const userGateway = GATEWAYS.getUserGateway();

export const getUserByForeignID = createAsyncThunk('user/getUserByForeignID', async (userForeignId: ID) => {
    const { user }: { user: User } = await userGateway.getUserByForeignId(userForeignId);
    return user;
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserToNull: (state) => {
            delete state.user;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserByForeignID.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(getUserByForeignID.rejected, () => {
            console.error('Error, could not find user');
        });
    },
});

export const { setUserToNull } = userSlice.actions;

export const userReducer = userSlice.reducer;
