import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Catalog } from '../../../../domain/catalog/catalog';

import { initialState } from './catelog.state';

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        viewCatalog: (state, action: PayloadAction<Catalog>) => {
            state.selectedCatalog = action.payload;
        },
        closeCatalog: (state) => {
            state.selectedCatalog = undefined;
        },
    },
});

export const { viewCatalog, closeCatalog } = catalogSlice.actions;
export const catalogReducer = catalogSlice.reducer;
