import React from 'react';
import './practiceDisplay.scss';
import { Practice } from '../../../../../domain/practice/practice';
import IPracticeCard from '../../../components/cards/practiceCard/practiceCard';
import { useTranslation } from 'react-i18next';
import { ID } from '../../../../../domain/shared';

type IPracticeDisplayProps = {
    practices: Practice[];
    canDisplaySearch: () => boolean;
    catalogId?: ID;
};

export function IPracticeDisplay({ practices, canDisplaySearch, catalogId }: IPracticeDisplayProps) {
    const { t } = useTranslation();
    return (
        <div className="practice-display">
            {canDisplaySearch() && (
                <p className="searched-practice-explanation">
                    {t('SEARCH_PRACTICE_EXPLANATION_BEGIN')}
                    <span className="green">{t('GREEN')}</span>
                    {t('SEARCH_PRACTICE_EXPLANATION_END')}
                </p>
            )}

            <div className="practice-list">
                {practices.map((practice) => (
                    <IPracticeCard
                        practice={practice}
                        displaySearched={canDisplaySearch()}
                        key={practice._id}
                        catalogId={catalogId}
                    />
                ))}
            </div>
        </div>
    );
}
