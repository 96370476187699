import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';
import { ListState } from '../list.state';

import { CatalogImport } from '../../../../../domain/catalog/catalog';
import { ID } from '../../../../../domain/shared';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const importCatalog = createAsyncThunk(
    `${baseName}/importCatalog`,
    async ({ catalog, owner }: { catalog: CatalogImport; owner: ID }, { rejectWithValue }) => {
        const toaster = Toaster.loading(translate('IMPORT_LOADING_TOASTER'));
        try {
            return { catalog: await catalogGateway.importCatalog(catalog, owner), toaster };
        } catch (e) {
            return rejectWithValue({ catalog, toaster, e });
        }
    },
);

export const buildImportCatalogReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(importCatalog.fulfilled, (state, action) => {
        const { catalog, toaster } = action.payload;
        toaster.updateSuccess(translate('IMPORT_SUCCESS_TOASTER'));
        state.catalogs.push(catalog);
    });

    builder.addCase(importCatalog.rejected, (state, action) => {
        const { toaster } = action.payload as { toaster: Toaster };
        const errorMessage = (action.payload as { e?: { response?: { data?: { message?: string } } } })?.e?.response
            ?.data?.message;
        if (errorMessage && errorMessage.startsWith('No practices')) {
            toaster.updateError(translate('IMPORT_FAILURE_TOASTER_NO_PRACTICES'));
        } else {
            toaster.updateError(translate('IMPORT_FAILURE_TOASTER'));
        }
        throw action.error;
    });
};
