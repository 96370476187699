import React, { useEffect } from 'react';

import { useExampleInformation } from './exampleInformation.logic';

import { PracticeFull } from '../../../../../../domain/practice/practice';
import { ICodeViewer } from '../../../codeViewer/codeViewer';

import './exampleInformation.scss';
import { IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

type IExampleInformationProps = {
    practice: PracticeFull;
    isLoadingPractice: boolean;
};

export function IExampleInformation({ practice, isLoadingPractice }: IExampleInformationProps) {
    const {
        hasNextNegativeFile,
        hasNextPositiveFile,
        hasPreviousNegativeFile,
        hasPreviousPositiveFile,
        nextPositiveFile,
        nextNegativeFile,
        previousPositiveFile,
        previousNegativeFile,
        hasNegatives,
        hasPositives,
        example,
        setPositiveWidget,
        setNegativeWidget,
        initExample,
    } = useExampleInformation(practice);

    useEffect(initExample, [practice]);
    return !isLoadingPractice ? (
        <div className="examples-information">
            {hasPositives() && example.positive ? (
                <div className={hasNegatives() ? 'positive-example-display' : 'example-display'}>
                    <div className="example-control-button">
                        <IconButton disabled={!hasPreviousPositiveFile()} onClick={previousPositiveFile}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <div className="file-path">{example.positive.path}</div>
                        <IconButton disabled={!hasNextPositiveFile()} onClick={nextPositiveFile}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </div>
                    <ICodeViewer example={example.positive} practice={practice} setWidget={setPositiveWidget} />
                </div>
            ) : null}
            {hasNegatives() && example.negative ? (
                <div className={hasPositives() ? 'negative-example-display' : 'example-display'}>
                    <div className="example-control-button">
                        <IconButton disabled={!hasPreviousNegativeFile()} onClick={previousNegativeFile}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <div className="file-path">{example.negative.path}</div>
                        <IconButton disabled={!hasNextNegativeFile()} onClick={nextNegativeFile}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </div>
                    <ICodeViewer example={example.negative} practice={practice} setWidget={setNegativeWidget} />
                </div>
            ) : null}
        </div>
    ) : null;
}
