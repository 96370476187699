import React, { useEffect } from 'react';
import './userPage.scss';
import { useUserPage } from './userPage.logic';
import { useParams } from 'react-router-dom';
import { IUserInformation } from '../../components/userPageComponents/userInformation/userInformation';
import { IUserEdition } from '../../components/userPageComponents/userEdition/userEdition';

export function IUserPage() {
    const { userId } = useParams();
    const { selectedUser, setUserEffect, isEditing, setIsEditing, userChangeCallback } = useUserPage();

    useEffect(() => setUserEffect(userId), [userId]);

    return (
        <div className="main-page-container">
            {selectedUser ? (
                <div className="user-page-content">
                    {isEditing ? (
                        <IUserEdition
                            selectedUser={selectedUser}
                            setIsEditing={setIsEditing}
                            userChangeCallback={userChangeCallback}
                        />
                    ) : (
                        <IUserInformation selectedUser={selectedUser} setIsEditing={setIsEditing} />
                    )}
                </div>
            ) : null}
        </div>
    );
}
