import React from 'react';
import './userEdition.scss';
import { User } from '../../../../../domain/user/user';
import { IUserPageButtons } from '../userPageButtons/userPageButtons';
import { IUserCategoriesForEdition } from '../userCategoriesForEdition/UserCategoriesForEdition';
import { useUserEditionLogic } from './userEdition.logic';
import { IAddCategoryForm } from './addCategoryForm/addCategoryForm';
import { ISocialLinkEdition } from '../SocialLinksEdition/socialLinkEdition';

type IUserEditionProps = {
    selectedUser: User;
    setIsEditing: (isEditing: boolean) => void;
    userChangeCallback: (user: User) => void;
};

export function IUserEdition({ selectedUser, setIsEditing, userChangeCallback }: IUserEditionProps) {
    const {
        isAddCategoryFormOpen,
        setIsAddCategoryFormOpen,
        user,
        deleteCategory,
        onDetailsChange,
        addCategory,
        deleteLink,
    } = useUserEditionLogic(selectedUser);

    return (
        <div className="user-edition-content">
            <ISocialLinkEdition user={user} deleteLink={deleteLink} />
            <div className="user-profile-picture-container">
                <img src="images/face.svg" alt="" className="user-profile-picture" />
            </div>
            <div className="user-name-container">
                <h1 className="user-name">{user.name}</h1>
            </div>
            <IUserCategoriesForEdition categories={user.categories ?? []} deleteElement={deleteCategory} />
            <IAddCategoryForm
                addCategory={addCategory}
                isAddCategoryFormOpen={isAddCategoryFormOpen}
                setIsAddCategoryFormOpen={setIsAddCategoryFormOpen}
            />
            <div className="description">
                <textarea className="description-text-area" defaultValue={user.details} onChange={onDetailsChange} />
            </div>

            <IUserPageButtons
                selectedUser={selectedUser}
                isEditing
                setIsEditing={setIsEditing}
                userChangeCallback={() => {
                    userChangeCallback(user);
                }}
            />
        </div>
    );
}
