import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Category } from '../../../../domain/category/category';

import { initialState } from './category.state';

import { buildGetAllCategoriesReducers } from './asyncThunks/getCategories.thunk';

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        selectCategory: (state, action: PayloadAction<Category>) => {
            if (!state.selectedCategories.find((category) => category === action.payload)) {
                state.selectedCategories.push(action.payload);
            }
        },
        unselectCategory: (state, action: PayloadAction<Category>) => {
            state.selectedCategories = state.selectedCategories.filter((category) => category !== action.payload);
        },
    },
    extraReducers: (builder) => {
        buildGetAllCategoriesReducers(builder);
    },
});

export const { selectCategory, unselectCategory } = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
