import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';

import { ListState } from '../list.state';
import { RootState } from '../../../store';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const loadMoreCatalogs = createAsyncThunk(`${baseName}/loadMoreCatalogs`, async (_, { getState }) => {
    const state = getState() as RootState;
    const { category: { selectedCategories }, list: { search, catalogsPage, pageSize, hasMoreCatalogs } } = state;

    return hasMoreCatalogs
        ? await catalogGateway.search(search, selectedCategories, catalogsPage, pageSize)
        : { catalogs: [], hasMore: false };
});

export const buildLoadMoreCatalogsReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(loadMoreCatalogs.fulfilled, (state, action) => {
        state.catalogs = [...state.catalogs, ...action.payload.catalogs];
        state.hasMoreCatalogs = action.payload.hasMore;
    });

    builder.addCase(loadMoreCatalogs.rejected, () => {
        Toaster.error(translate('LOAD_MORE_CATALOGS_FAILURE'));
    });
};
