import { Gateways } from '../../../corelogic/gateways/Gateways.interface';
import { CatalogGateway } from '../../../corelogic/gateways/CatalogGateway.interface';
import { UserGateway } from '../../../corelogic/gateways/UserGateway.interface';
import { PracticeGateway } from '../../../corelogic/gateways/PracticeGateway.interface';
import { CategoryGateway } from '../../../corelogic/gateways/CategoryGateway.interface';

import { CatalogAPIGateway } from './CatalogAPIGateway';
import { CategoryAPIGateway } from './CategoryAPIGateway';
import { PracticeAPIGateway } from './PracticeAPIGateway';
import { UserAPIGateway } from './UserAPI.gateway';

import { Services } from '../services/Services.service';
import { UserCartAPIGateway } from './UserCartAPIGateway';
import { UserCartGateway } from '../../../corelogic/gateways/UserCartGateway.interface';
import { CommentsGateway } from '../../../corelogic/gateways/CommentsGateway.interface';
import { CommentsAPIGateway } from './CommentsAPIGateway';

export class GatewaysAPI implements Gateways {
    private readonly apiService = Services.instance().api;

    private readonly catalogGateway = new CatalogAPIGateway(this.apiService);
    private readonly categoryGateway = new CategoryAPIGateway(this.apiService);
    private readonly practiceGateway = new PracticeAPIGateway(this.apiService);
    private readonly userGateway = new UserAPIGateway(this.apiService);
    private readonly userCartGateway = new UserCartAPIGateway(this.apiService);
    private readonly commentsGateway = new CommentsAPIGateway(this.apiService);

    getCatalogGateway(): CatalogGateway {
        return this.catalogGateway;
    }

    getCategoryGateway(): CategoryGateway {
        return this.categoryGateway;
    }

    getPracticeGateway(): PracticeGateway {
        return this.practiceGateway;
    }

    getUserGateway(): UserGateway {
        return this.userGateway;
    }

    getUserCartGateway(): UserCartGateway {
        return this.userCartGateway;
    }

    getCommentsGateway(): CommentsGateway {
        return this.commentsGateway;
    }
}
