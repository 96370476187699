import { UserCartGateway } from '../../../corelogic/gateways/UserCartGateway.interface';
import { Cart } from '../../../domain/cart/cart';
import { ID } from '../../../domain/shared';

export class UserCartInMemoryGateway implements UserCartGateway {
    async getCartForUser(userId: ID): Promise<Cart> {
        void userId;
        return { practiceList: [], catalogList: [] };
    }

    async setCartForUser(cart: Cart, userId: ID): Promise<void> {
        void userId;
    }
}
