import { getDispatch } from '../../../redux/store';

import { loadMorePractices as loadMorePracticesAction } from '../../../redux/slices/list/asyncThunks/loadMorePractices.thunk';
import { setMorePracticeSearch as setMorePracticeSearchAction } from '../../../redux/slices/list/list.slice';

export function loadMorePractices() {
    const dispatch = getDispatch();
    dispatch(setMorePracticeSearchAction());
    dispatch(loadMorePracticesAction());
}
