import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './cart.state';

import { buildExportCatalogReducers } from './asyncThunks/exportCatalog.thunk';
import { buildExportPersonalisedCatalogReducers } from './asyncThunks/exportPersonalisedCatalog.thunk';

import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

import { translate } from '../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../utils/toaster';
import { buildLoadCartFromDatabaseActionReducers } from './asyncThunks/loadCartFromDatabase';

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addCatalogToCartAction: (state, action: PayloadAction<Catalog>) => {
            if (!state.value.catalogList.find(({ _id }) => _id === action.payload._id)) {
                state.value.catalogList.push(action.payload);
                Toaster.success(translate('CATALOG_ADDED_SUCCESSFULLY_TO_CART'));
            } else {
                Toaster.warn(translate('CATALOG_NOT_ADDED_TO_CART'));
            }
        },
        addPracticeToCartAction: (state, action: PayloadAction<Practice>) => {
            if (!state.value.practiceList.find(({ _id }) => _id === action.payload._id)) {
                state.value.practiceList.push(action.payload);
                Toaster.success(translate('PRACTICE_ADDED_SUCCESSFULLY_TO_CART'));
            } else {
                Toaster.warn(translate('PRACTICE_NOT_ADDED_TO_CART'));
            }
        },
        removeCatalogFromCartAction: (state, action: PayloadAction<Catalog>) => {
            const numberOfElements = state.value.catalogList.length;
            state.value.catalogList = state.value.catalogList.filter(({ _id }) => _id !== action.payload._id);
            if (numberOfElements !== state.value.catalogList.length) {
                Toaster.success(translate('CATALOG_REMOVED_SUCCESSFULLY_TO_CART'));
            } else {
                Toaster.warn(translate('CATALOG_NOT_REMOVED_TO_CART'));
            }
        },
        removePracticeFromCartAction: (state, action: PayloadAction<Practice>) => {
            const numberOfElements = state.value.practiceList.length;
            state.value.practiceList = state.value.practiceList.filter(({ _id }) => _id !== action.payload._id);
            if (numberOfElements !== state.value.practiceList.length) {
                Toaster.success(translate('PRACTICE_REMOVED_SUCCESSFULLY_TO_CART'));
            } else {
                Toaster.warn(translate('PRACTICE_NOT_REMOVED_TO_CART'));
            }
        },
        emptyCartAction: (state) => {
            state.value = {
                practiceList: [],
                catalogList: [],
            };
        },
    },
    extraReducers: (builder) => {
        buildExportCatalogReducers(builder);
        buildExportPersonalisedCatalogReducers(builder);
        buildLoadCartFromDatabaseActionReducers(builder);
    },
});

export const {
    addCatalogToCartAction,
    addPracticeToCartAction,
    removeCatalogFromCartAction,
    removePracticeFromCartAction,
    emptyCartAction,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
