import axios from 'axios';

const DEFAULT_HTTP_TIMEOUT = 8000;

export const axiosWrapper = axios.create({
    timeout: DEFAULT_HTTP_TIMEOUT,
});

axiosWrapper.interceptors.request.use((config) => {
    config.headers = config.headers || {};
    return config;
});

axiosWrapper.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error),
);
