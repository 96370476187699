import { CommentsGateway } from '../../../corelogic/gateways/CommentsGateway.interface';
import { ID } from '../../../domain/shared';
import { Comment } from '../../../domain/practice/Comment';

export class CommentsInMemoryGateway implements CommentsGateway {
    private comments: Map<ID, Comment[]> = new Map<ID, Comment[]>();

    async addComment(comment: Comment, practiceId: ID): Promise<void> {
        if (!this.comments.has(practiceId)) {
            this.comments.set(practiceId, [comment]);
        } else {
            this.comments.get(practiceId)?.push(comment);
        }
    }

    async getComments(practiceId: ID): Promise<Comment[]> {
        return this.comments.get(practiceId) ?? [];
    }

    async deleteComment(comment: Comment): Promise<void> {
        const commentId = comment._id;
        if (!commentId) {
            return;
        }
        this.comments.forEach((comments, id) => {
            this.comments.set(
                id,
                comments.filter(({ _id }) => _id !== commentId),
            );
        });
    }
}
