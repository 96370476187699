import React from 'react';
import './validationModal.scss';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

type IValidationModalProps = {
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    onValidation: () => void;
    children?: React.ReactNode;
};

export function IValidationModal({ children, setOpen, isOpen, onValidation }: IValidationModalProps) {
    const { t } = useTranslation();
    return (
        <Modal open={isOpen} onClose={() => setOpen(false)} className="validation-modal modal">
            <div className="validation-modal-content">
                <div className="validation-message">{children}</div>
                <div className="validation-modal-buttons">
                    <button className="hub-button secondary" type="button" onClick={() => setOpen(false)}>
                        {t('CANCEL_BUTTON')}
                    </button>
                    <button className="hub-button error" type="button" onClick={onValidation}>
                        {t('VALIDATE_BUTTON')}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
