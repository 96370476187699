import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';
import { CartState } from '../cart.state';

import { Catalog } from '../../../../../domain/catalog/catalog';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Services } from '../../../../../adapters/secondary/services/Services.service';
import { Toaster } from '../../../../../utils/toaster';
import { ID } from '../../../../../domain/shared';

export const exportCatalog = createAsyncThunk(
    `${baseName}/exportCatalog`,
    async ({ catalog, userId }: { catalog: Catalog; userId: ID }, { rejectWithValue }) => {
        const toaster = Toaster.loading(translate('EXPORT_LOADING_TOASTER'));
        try {
            return { result: await catalogGateway.exportCatalogById(catalog._id, userId), toaster };
        } catch (e) {
            return rejectWithValue({ catalog, toaster });
        }
    },
);

export const buildExportCatalogReducers = (builder: ActionReducerMapBuilder<CartState>) => {
    builder.addCase(exportCatalog.fulfilled, (state, action) => {
        const { toaster } = action.payload as { toaster: Toaster };
        toaster.updateSuccess(translate('EXPORT_SUCCESS_TOASTER'));

        Services.instance().catalog.exportCatalog(action.payload.result);
        state.value.catalogList = [];
    });

    builder.addCase(exportCatalog.rejected, (_, action) => {
        const { catalog, toaster } = action.payload as { catalog: Catalog; toaster: Toaster };
        toaster.updateError(translate('EXPORT_FAILURE_TOASTER', { name: catalog.name }));
    });
};
