import React, { useEffect, useState } from 'react';
import './UserCategoriesForEdition.scss';
import { Badge } from '@mui/material';

type IUserCategoriesPropsForEdition = {
    categories: string[];
    deleteElement: (index: number) => void;
};

export function IUserCategoriesForEdition({
    categories,
    deleteElement = (index: number) => {
        void index;
    },
}: IUserCategoriesPropsForEdition) {
    const [cats, setCats] = useState(categories);

    useEffect(() => setCats(categories), [categories]);

    return (
        <div className="user-categories">
            {cats.map((category, index) => (
                <Badge
                    badgeContent={
                        <button
                            className="hub-button outline-filled delete-category-button"
                            type="button"
                            onClick={() => deleteElement(index)}
                        >
                            X
                        </button>
                    }
                    key={`ucat-${category}-${index}`}
                    overlap="circular"
                >
                    <div className="user-category-gradient-border">
                        <div className="user-category">{category}</div>
                    </div>
                </Badge>
            ))}
        </div>
    );
}
