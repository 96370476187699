import { Catalog } from '../../../../domain/catalog/catalog';
import { getDependencies, getDispatch } from '../../../redux/store';
import {
    viewCatalog as viewCatalogAction,
    closeCatalog as closeCatalogAction,
} from '../../../redux/slices/catalog/catalog.slice';
import { closePractice } from '../../practice/viewPractice/viewPractice';

let lastLocation: string;

export function openCatalog(catalog: Catalog) {
    setCatalog(catalog);
    closePractice();
    lastLocation = getDependencies().navigationService.location;
    getDependencies().navigationService.navigate(`/catalog/${catalog._id}`);
}

export function setCatalog(catalog: Catalog) {
    const dispatch = getDispatch();
    dispatch(viewCatalogAction(catalog));
}

export function closeCatalog() {
    const dispatch = getDispatch();
    dispatch(closeCatalogAction());
    getDependencies().navigationService.navigate(lastLocation ?? '/');
}
