import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, practiceGateway } from './baseThunkInfo';

import { ListState } from '../list.state';
import { RootState } from '../../../store';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const searchPractices = createAsyncThunk(`${baseName}/searchPractices`, async (_, { getState }) => {
    const state = getState() as RootState;
    const { category: { selectedCategories }, list: { search, practicesPage, pageSize } } = state;

    return await practiceGateway.search(search, selectedCategories, practicesPage, pageSize);
});

export const buildSearchPracticesReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(searchPractices.pending, state => {
        state.isFetchingPractices = true;
    });

    builder.addCase(searchPractices.fulfilled, (state, action) => {
        state.practices = [...action.payload.practices];
        state.practicesTotalSearch = action.payload.totalSearchNumber;
        state.hasMorePractices = action.payload.hasMore;
        state.isFetchingPractices = false;
    });

    builder.addCase(searchPractices.rejected, state => {
        state.isFetchingPractices = false;
        Toaster.error(translate('SEARCH_PRACTICES_FAILURE'));
    });
};
