import React from 'react';
import INavbar from './navbar/navbar';
import './header.scss';
import { NavigationService } from '../../../secondary/services/Navigation.service';

export default function IHeader() {
    const navigationService = new NavigationService();

    return (
        <div className="header">
            <button className="promyze-logo-button" type="button" onClick={() => navigationService.navigate('/')}>
                <img src="/images/hub-color.svg" alt="Promyze Logo" className="promyze-logo" />
            </button>
            <INavbar />
        </div>
    );
}
