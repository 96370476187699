import { GatewaysAPI } from '../../adapters/secondary/api/APIGateways';
import { GatewaysInMemory } from '../../adapters/secondary/inMemory/GatewaysInMemory';
import { Env, getEnv } from '../../utils/env';
import { Gateways } from './Gateways.interface';

export const GATEWAYS: Gateways = selectGateway();

function selectGateway() {
    const nodeEnv = getEnv(Env.NODE_ENV);
    if (nodeEnv === 'TEST') {
        return new GatewaysInMemory();
    } else {
        return new GatewaysAPI();
    }
}
