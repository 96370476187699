import { Category } from '../../../../domain/category/category';

import { getDispatch, getState } from '../../../redux/store';
import {
    selectCategory as selectCategoryAction,
    unselectCategory as unselectCategoryAction,
} from '../../../redux/slices/category/category.slice';

export async function selectCategory(category: Category) {
    const dispatch = getDispatch();
    await dispatch(selectCategoryAction(category));
}

export async function unselectCategory(category: Category) {
    const dispatch = getDispatch();
    await dispatch(unselectCategoryAction(category));
}

export function isCategorySelected(category: Category): boolean {
    const { selectedCategories } = getState().category;
    return selectedCategories.find((c) => c === category) !== undefined;
}
