import { Practice, PracticeSearched } from '../../../../domain/practice/practice';
import { Catalog } from '../../../../domain/catalog/catalog';
import { getDependencies } from '../../../redux/store';

class NoSuchIndexError extends Error {}

export function nextPractice(practice: Practice | PracticeSearched, catalog: Catalog) {
    try {
        const newPracticeIdx = getPracticeIndex(practice, catalog) + 1;
        if (newPracticeIdx < catalog.practices.length) {
            const url = `/catalog/${catalog._id}/${catalog.practices[newPracticeIdx]._id}`;
            getDependencies().navigationService.navigate(url);
        }
    } catch (error: unknown) {
        if (!(error instanceof NoSuchIndexError)) {
            throw error;
        }
    }
}

export function previousPractice(practice: Practice | PracticeSearched, catalog: Catalog) {
    try {
        const newPracticeIdx = getPracticeIndex(practice, catalog) - 1;
        if (newPracticeIdx >= 0) {
            const url = `/catalog/${catalog._id}/${catalog.practices[newPracticeIdx]._id}`;
            getDependencies().navigationService.navigate(url);
        }
    } catch (error: unknown) {
        if (!(error instanceof NoSuchIndexError)) {
            throw error;
        }
    }
}

export function getPracticeIndex(practice: Practice | PracticeSearched, catalog: Catalog): number {
    const practiceIdx = catalog.practices.findIndex(({ _id }) => _id === practice._id);
    if (practiceIdx < 0) {
        throw new NoSuchIndexError('Practice does not belong to catalog');
    }
    return practiceIdx;
}

export function isFirstPractice(practice: Practice | PracticeSearched, catalog: Catalog): boolean {
    try {
        const practiceIdx = getPracticeIndex(practice, catalog);
        return practiceIdx === 0;
    } catch (e: unknown) {
        return false;
    }
}

export function isLastPractice(practice: Practice | PracticeSearched, catalog: Catalog): boolean {
    try {
        const practiceIdx = getPracticeIndex(practice, catalog);
        return practiceIdx === catalog.practices.length - 1;
    } catch (e: unknown) {
        return false;
    }
}
