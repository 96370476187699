import React, { useEffect, useRef, useState } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';

import './markdownViewer.style.scss';
import './light.scss';
import './dark.scss';
interface MarkdownViewerProps {
    rawContent: string;
    onLoad?: () => void;
    theme?: 'light-theme' | 'dark-theme';
}

const MarkdownViewer = ({ rawContent, onLoad, theme }: MarkdownViewerProps): JSX.Element => {
    const [htmlMarkdown, setHtmlMarkdown] = useState<string>('');
    const [themeClass, setThemeClass] = useState<string>('');
    const viewerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const body = document.querySelector('body');
        const selectedThemeClass = theme ?? body?.className ?? 'dark-theme';
        setThemeClass(selectedThemeClass);

        marked.setOptions({
            langPrefix: `hljs-${selectedThemeClass} language-`,
            highlight(code, lang) {
                return hljs.highlightAuto(code, [lang]).value;
            },
        });
    });

    useEffect(() => {
        const unsafeRenderedMarkdown = marked.parse(rawContent);
        const html = DOMPurify.sanitize(unsafeRenderedMarkdown);
        setHtmlMarkdown(html);
    }, [rawContent]);

    useEffect(() => {
        if (!viewerRef || !viewerRef.current) {
            return;
        }
        viewerRef.current.innerHTML = htmlMarkdown;
        if (onLoad) {
            onLoad();
        }
    }, [viewerRef, htmlMarkdown]);

    return <div className={`markdown-viewer ${themeClass}`} ref={viewerRef} />;
};

export default MarkdownViewer;
