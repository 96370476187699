import React from 'react';
import './userPageButtons.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import { useAuth } from '@frontegg/react-hooks';
import { useAuthInfo } from '@propelauth/react';
import { User } from '../../../../../domain/user/user';

type IUserPageButtonsProps = {
    selectedUser: User;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    userChangeCallback?: () => void;
};

export function IUserPageButtons({ selectedUser, isEditing, setIsEditing, userChangeCallback }: IUserPageButtonsProps) {
    const { t } = useTranslation();
    //const { isAuthenticated } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;
    const user = authInfo.user;

    const canUserSeeButton = () => isAuthenticated && user?.userId === selectedUser.foreignId;

    const onClickValidate = () => {
        setIsEditing(false);
        if (userChangeCallback) {
            userChangeCallback();
        }
    };

    return (
        <div className="button-container">
            {canUserSeeButton() && !isEditing ? (
                <div className="edit-profile-button-container">
                    <button className="hub-button" type="button" onClick={() => setIsEditing(true)}>
                        {t('EDIT_PROFILE_BUTTON')}
                    </button>
                </div>
            ) : null}

            {canUserSeeButton() && !isEditing ? (
                <Link to={'/import'} className="link-button">
                    <button className="hub-button" type="button">
                        {t('IMPORT_LINK')}
                    </button>
                </Link>
            ) : null}

            {canUserSeeButton() && isEditing ? (
                <div className="validate-profile-edition">
                    <button className="hub-button success" type="button" onClick={onClickValidate}>
                        {t('SAVE_BUTTON')}
                    </button>
                </div>
            ) : null}

            {canUserSeeButton() && isEditing ? (
                <div className="cancel-profile-edition">
                    <button className="hub-button error" type="button" onClick={() => setIsEditing(false)}>
                        {t('CANCEL_BUTTON')}
                    </button>
                </div>
            ) : null}
        </div>
    );
}
