import { GATEWAYS } from '../../../gateways/gatewaysUtils';
import { ID } from '../../../../domain/shared';
import { getDependencies, RootState } from '../../../redux/store';

const practiceGateway = GATEWAYS.getPracticeGateway();

export async function likePractice(practiceId: ID) {
    const state: RootState = getDependencies().store.getState();
    const userId = state.user.user?._id;
    if (!userId) {
        throw new Error('No user connected');
    }
    return practiceGateway.likeAction(practiceId, userId);
}

export async function dislikePractice(practiceId: ID) {
    const state: RootState = getDependencies().store.getState();
    const userId = state.user.user?._id;
    if (!userId) {
        throw new Error('No user connected');
    }
    return practiceGateway.dislikeAction(practiceId, userId);
}
