import React from 'react';
import './SocialButtons.scss';
import { User } from '../../../../../domain/user/user';

type ISocialButtonsProps = {
    user: User;
};

export function ISocialButtons({ user: { socialLinks } }: ISocialButtonsProps) {
    return (
        <div className="social">
            {socialLinks?.map(({ platform }) => (
                <a
                    className="social-image-container"
                    key={platform}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialLinks.find((social) => social.platform === platform)?.link}
                >
                    <img src={`/images/socials/${platform}.svg`} alt="" className="social" />
                </a>
            ))}
        </div>
    );
}
