import { useState } from 'react';

import { Practice, PracticeFull } from '../../../../../domain/practice/practice';

import { GATEWAYS } from '../../../../../corelogic/gateways/gatewaysUtils';
import { findCatalogFromPractice } from '../../../../../corelogic/usecases/catalog/findCatalogFromPractice/findCatalogFromPractice';
import { getDependencies } from '../../../../../corelogic/redux/store';
import { Env, getEnv } from '../../../../../utils/env';
import { Toaster } from '../../../../../utils/toaster';
import { translate } from '../../../../secondary/services/translation.service';
import { getSelectedCatalog } from '../../../../../corelogic/redux/slices/catalog/catalog.selector';
import { useSelector } from 'react-redux';

export function usePracticeModal(practice: Practice) {
    const [catalog, setCatalog] = useState(findCatalogFromPractice(practice));
    const [isLoadingPractice, setIsLoadingPractice] = useState<boolean>(true);
    const [practiceFull, setPracticeFull] = useState<PracticeFull>();
    const selectedCatalog = useSelector(getSelectedCatalog);

    const getFullPracticeEffect = () => {
        const asyncCall = async () => {
            const fullPractice = await GATEWAYS.getPracticeGateway().getPracticeDetails(practice._id);
            setPracticeFull(fullPractice);
        };

        setIsLoadingPractice(true);
        setCatalog(findCatalogFromPractice(practice) || selectedCatalog);
        asyncCall().finally(() => setIsLoadingPractice(false));
    };

    const shouldDisplayGoToCatalog = () => {
        const navigation = getDependencies().navigationService;
        const userIsOnCatalogPage = navigation.location === '/catalog';
        return !!catalog && !userIsOnCatalogPage;
    };

    const copyLinkToPractice = () => {
        const catalogId = catalog?._id;
        const practiceId = practice?._id;
        navigator.clipboard
            .writeText(`${getEnv(Env.REACT_APP_FRONTEND_BASE_URL)}/catalog/${catalogId}/${practiceId}`)
            .then(() => {
                Toaster.success(translate('COPY_ULR_TO_CLIPBOARD_SUCCESS'));
            })
            .catch(() => {
                Toaster.error(translate('COPY_ULR_TO_CLIPBOARD_FAILURE'));
            });
    };

    return {
        catalog,
        isLoadingPractice,
        practiceFull,
        getFullPracticeEffect,
        shouldDisplayGoToCatalog,
        copyLinkToPractice,
    };
}
