import { FunctionComponentElement, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import CodeMirror, { Editor, LineWidget } from 'codemirror';

import { IExampleWidget } from './exampleWidget/exampleWidget';

import { Example } from '../../../../domain/practice/example';

import { getMode } from '../../../../utils/codeMirrorUtils';

export const useCodeViewer = (
    fc: FunctionComponentElement<typeof IExampleWidget>,
    setWidget: (widget: LineWidget) => void,
    example: Example,
) => {
    const [editor, setEditor] = useState<Editor | undefined>();
    const codeMirrorRef = useRef<HTMLDivElement>(null);

    const addExample = () => {
        if (editor) {
            const tagDiv = document.createElement('div');
            ReactDOM.render(fc, tagDiv);
            const widget = editor.addLineWidget(example.position.start.line, tagDiv, { above: true });
            setWidget(widget);
            const height = editor.heightAtLine(example.position.start.line, 'local');
            editor.scrollTo(0, height);
        }
    };

    const editorEffect = () => {
        if (editor) {
            editor.setOption('mode', getMode(example));
            editor.setValue(example.content);
        }
    };

    const referenceEffect = () => {
        if (codeMirrorRef !== null && codeMirrorRef.current !== null) {
            setEditor(
                CodeMirror(codeMirrorRef.current, {
                    mode: getMode(example),
                    value: example.content,
                    readOnly: true,
                    lineNumbers: true,
                    lineWrapping: true,
                    theme: 'monokai',
                    configureMouse: () => {
                        return { addNew: false };
                    },
                }),
            );
        }
    };

    return { codeMirrorRef, editor, addExample, editorEffect, referenceEffect };
};
