import { isSocialPlatform, SocialLink, SocialPlatforms } from '../../../../../domain/user/user';
import React, { useState } from 'react';
import { Toaster } from '../../../../../utils/toaster';
import { translate } from '../../../../secondary/services/translation.service';

export function useSocialLinkEdition(socialLinks: SocialLink[], deleteLink: (platform: SocialPlatforms) => void) {
    let platformList = Object.keys(SocialPlatforms);
    const updatePlatformList = () => {
        platformList = platformList.filter(
            (platform) => !socialLinks?.find((socialLink) => socialLink.platform === platform),
        );
    };

    updatePlatformList();

    const deleteSocialLink = (platform: SocialPlatforms) => {
        deleteLink(platform);
        updatePlatformList();
    };

    const socialLinkSelectRef = React.createRef<HTMLSelectElement>();
    const socialLinkInputRef = React.createRef<HTMLInputElement>();

    const addLink = () => {
        const link = socialLinkInputRef.current?.value;
        const platform = socialLinkSelectRef.current?.value;
        if (link && isSocialPlatform(platform)) {
            try {
                const urlLink = new URL(link);
                socialLinks?.push({ link: urlLink.toString(), platform });
                setIsAdding(false);
            } catch (_) {
                Toaster.error(translate('BAD_URL_ERROR_MESSAGE'));
            }
        }
    };

    const onKeyDown = ({ key }: { key: string }) => {
        const enterKeyCode = 'Enter';
        if (key === enterKeyCode) {
            addLink();
        }
    };

    const [isAdding, setIsAdding] = useState(false);

    return {
        isAdding,
        setIsAdding,
        platformList,
        deleteSocialLink,
        addLink,
        socialLinkInputRef,
        socialLinkSelectRef,
        onKeyDown,
    };
}
