import { getDispatch } from '../../../redux/store';

import { initNewCatalogSearch as initNewCatalogSearchAction } from '../../../redux/slices/list/list.slice';
import { searchCatalogs as searchCatalogsAction } from '../../../redux/slices/list/asyncThunks/searchCatalogs.thunk';

export function searchCatalogs() {
    const dispatch = getDispatch();
    dispatch(initNewCatalogSearchAction());
    dispatch(searchCatalogsAction());
}
