import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, practiceGateway } from './baseThunkInfo';

import { ListState } from '../list.state';
import { RootState } from '../../../store';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const loadMorePractices = createAsyncThunk(`${baseName}/loadMorePractices`, async (_, { getState }) => {
    const state = getState() as RootState;
    const { category: { selectedCategories }, list: { search, practicesPage, pageSize, hasMorePractices } } = state;

    return hasMorePractices
        ? await practiceGateway.search(search, selectedCategories, practicesPage, pageSize)
        : { practices: [], hasMore: false };
});

export const buildLoadMorePracticesReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(loadMorePractices.fulfilled, (state, action) => {
        state.practices = [...state.practices, ...action.payload.practices];
        state.hasMorePractices = action.payload.hasMore;
    });

    builder.addCase(loadMorePractices.rejected, () => {
        Toaster.error(translate('LOAD_MORE_PRACTICES_FAILURE'));
    });
};
