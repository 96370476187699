import { CatalogGateway } from '../../../corelogic/gateways/CatalogGateway.interface';
import { Catalog, CatalogForSearch, CatalogImport, SearchCatalogs } from '../../../domain/catalog/catalog';
import { ID } from '../../../domain/shared';

export class CatalogInMemoryGateway implements CatalogGateway {
    deleteCatalog(catalogId: string): Promise<void> {
        void catalogId;
        return Promise.resolve();
    }

    // eslint-disable-next-line
    importCatalog(catalogToImport: CatalogImport, owner: ID): Promise<CatalogForSearch> {
        if (!catalogToImport.name) throw new Error('Corrupted catalog');

        return Promise.resolve({
            _id: 'catalogId',
            name: catalogToImport.name ?? '',
            description: '',
            owner: { id: owner, name: '' },
            practices: [],
            practicesCorrespondingToSearchCount: 0,
        });
    }

    // eslint-disable-next-line
    exportCatalogById(catalogId: string, userId: string): Promise<object> {
        return Promise.resolve({
            _id: catalogId,
            name: '',
            description: '',
            examples: [],
            files: [],
            practices: [],
        });
    }

    // eslint-disable-next-line
    exportPersonalisedCatalogWithPracticeIds(practiceIds: string[], userId: string): Promise<object> {
        return Promise.resolve(practiceIds);
    }

    async search(): Promise<SearchCatalogs> {
        return Promise.resolve({ catalogs: [], hasMore: false, totalSearchNumber: 0 });
    }

    getCatalogsForUser(userId: ID): Promise<Catalog[]> {
        void userId;
        return Promise.resolve([]);
    }

    getCatalogById(catalogId: ID): Promise<Catalog> {
        throw catalogId;
    }

    getCatalogFromPracticeId(practiceId: ID): Promise<Catalog> {
        throw practiceId;
    }
}
