import React, { useEffect, useState } from 'react';
import './UserCategories.scss';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

type IUserCategoriesProps = {
    categories: string[];
    canDelete?: boolean;
    deleteElement?: (index: number) => void;
};

export function IUserCategories({
    categories,
    canDelete = false,
    deleteElement = (index: number) => {
        void index;
    },
}: IUserCategoriesProps) {
    const [cats, setCats] = useState(categories);

    useEffect(() => setCats(categories), [categories.length]);

    return (
        <div className="user-categories">
            {cats.map((category, index) => (
                <div className="user-category-gradient-border" key={`ucat-${category}-${index}`}>
                    <div className="user-category">
                        {category}
                        {canDelete ? (
                            <IconButton size={'small'} onClick={() => deleteElement(index)}>
                                <Delete />
                            </IconButton>
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    );
}
