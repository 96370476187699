import { useState } from 'react';
import { cloneUser, SocialPlatforms, User } from '../../../../../domain/user/user';

export function useUserEditionLogic(selectedUser: User) {
    const [isAddCategoryFormOpen, setIsAddCategoryFormOpen] = useState(false);
    const [user, setUser] = useState<User>(cloneUser(selectedUser));

    const addCategory = (category: string) => user.categories?.push(category);

    if (!user.categories) user.categories = [];

    const deleteCategory = (index: number) => {
        if (!user.categories) return;
        user.categories = user.categories.filter((value, idx) => idx !== index);
        setUser(cloneUser(user));
    };

    const onDetailsChange = (event: { target: { value: string } }) => (user.details = event.target.value);

    const deleteLink = (platform: SocialPlatforms) => {
        user.socialLinks = user.socialLinks?.filter((link) => link.platform !== platform);
        setUser(cloneUser(user));
    };

    return {
        isAddCategoryFormOpen,
        setIsAddCategoryFormOpen,
        user,
        deleteCategory,
        onDetailsChange,
        addCategory,
        deleteLink,
    };
}
