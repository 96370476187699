import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

import { setCartInDatabase } from '../persistentCart/setCartInDatabase';
import { getDispatch } from '../../../redux/store';
import {
    emptyCartAction,
    removeCatalogFromCartAction,
    removePracticeFromCartAction,
} from '../../../redux/slices/cart/cart.slice';

export async function removeCatalogFromCart(catalog: Catalog) {
    const dispatch = getDispatch();
    const dispatchedAction = await dispatch(removeCatalogFromCartAction(catalog));
    await setCartInDatabase();
    return dispatchedAction;
}

export async function removePracticeFromCart(practice: Practice) {
    const dispatch = getDispatch();
    const dispatchedAction = await dispatch(removePracticeFromCartAction(practice));
    await setCartInDatabase();
    return dispatchedAction;
}

export async function emptyCart() {
    const dispatch = getDispatch();
    await dispatch(emptyCartAction());
    await setCartInDatabase();
}
