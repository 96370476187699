import React from 'react';
import { useTranslation } from 'react-i18next';

import './cart.scss';
import { useCartLogic } from './cart.logic';

import ICatalogCard from '../../components/cards/catalogCard/catalogCard';
import IPracticeCard from '../../components/cards/practiceCard/practiceCard';

export function ICartPage() {
    const { t } = useTranslation();
    const { catalogList, practiceList, emptyCart, exportSelectedCatalogsAndPractices } = useCartLogic();

    return (
        <div className='main-page-container'>
            <header className='page-header'>
                <h1 className='page-title'>{t('CART_PAGE_TITLE')}</h1>
            </header>
            <section className='action-buttons-container'>
                <button className='hub-button' type='button' onClick={emptyCart}>
                    {t('EMPTY_CART')}
                </button>
                <button className='hub-button success' type='button' onClick={exportSelectedCatalogsAndPractices}>
                    {t('EXPORT_CART')}
                </button>
            </section>
            <section className='cart-items'>
                {catalogList.map((catalog) => (
                    <ICatalogCard catalog={catalog} key={catalog._id} />
                ))}
                {practiceList.map((practice) => (
                    <IPracticeCard practice={practice} key={practice._id} />
                ))}
            </section>
        </div>
    );
}
