import { useSelector } from 'react-redux';
import { selectUser } from '../../../../corelogic/redux/slices/user/user.selector';
import { useState } from 'react';
import { User } from '../../../../domain/user/user';
import { GATEWAYS } from '../../../../corelogic/gateways/gatewaysUtils';

export function useUserPage() {
    const selectedUser = useSelector(selectUser);
    const userGateway = GATEWAYS.getUserGateway();

    const [user, setUser] = useState<User>();
    const [isEditing, setIsEditing] = useState(false);

    const setUserEffect = (userId: string | undefined) => {
        if (!userId) {
            setUser(selectedUser);
        } else {
            userGateway.getUserById(userId).then(({ user }) => {
                setUser(user);
            });
        }
    };

    const userChangeCallback = (user: User) => {
        setUser(user);
        userGateway.updateUser(user._id, user).then();
    };

    return { selectedUser: user, setUserEffect, isEditing, setIsEditing, userChangeCallback };
}
