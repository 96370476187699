import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCatalogsTotalSearch, getPracticesTotalSearch } from '../../../../corelogic/redux/slices/list/list.selector';

export enum ElementType {
    Catalog,
    Practices,
}

export function useListPage() {
    const catalogsTotalSearch = useSelector(getCatalogsTotalSearch);
    const practicesTotalSearch = useSelector(getPracticesTotalSearch);
    const [selectedElement, selectElement] = useState<ElementType>(ElementType.Catalog);

    const selectPractice = () => selectElement(ElementType.Practices);
    const selectCatalog = () => selectElement(ElementType.Catalog);

    return { catalogsTotalSearch, practicesTotalSearch, selectedElement, selectPractice, selectCatalog };
}
