import { Catalog } from '../../../domain/catalog/catalog';

export class CatalogService {
    // eslint-disable-next-line
    exportCatalog(catalog: any) {
        const catalogToExport = JSON.stringify(catalog);
        const file = new Blob([catalogToExport], { type: 'application/json' });
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = (catalog.name || catalog.catalog?.name) + '.json';
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    isCatalog(possibleCatalog: unknown): possibleCatalog is Catalog {
        const catalog = possibleCatalog as Catalog;
        return !(
            catalog._id === undefined ||
            catalog.name === undefined ||
            catalog.description === undefined ||
            catalog.practices === undefined ||
            catalog.owner === undefined
        );
    }
}
