import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationInterface } from './NavigationInterface';

export class NavigationService implements NavigationInterface {
    private _navigate = useNavigate();
    private _location = useLocation();

    navigate(toURL: string): void {
        this._navigate(toURL);
    }

    get location(): string {
        return this._location.pathname;
    }
}
