import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './whatIsPromyze.style.scss';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Platform = {
    name: string;
    icon: string;
}

const platforms: Platform[] = [
    {
        name: 'GitLab',
        icon: '/images/logos/gitlab-logo.png',
    },
    {
        name: 'GitHub',
        icon: '/images/logos/github.png',
    },
    {
        name: 'Bitbucket',
        icon: '/images/logos/bitbucket.png',
    },
    {
        name: 'Azure DevOps',
        icon: '/images/logos/azure.png',
    },
    {
        name: 'Visual Studio Code',
        icon: '/images/logos/Visual_Studio_Code_1.35_icon.png',
    },
    {
        name: 'Visual Studio',
        icon: '/images/logos/Visual_Studio_Icon_2019.png',
    },
    {
        name: 'JetBrains',
        icon: '/images/logos/JetBrains_logo.png',
    },
    {
        name: 'Eclipse',
        icon: '/images/logos/eclipse.png',
    },

];

const WhatIsPromyze = (): JSX.Element => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const handleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const handleClose = (): void => {
        setIsOpen(false);
    };

    return (
        <section className={'what-is-promyze'}>
            <article className={'promyze-button'}>
                <button type={'button'} className={`open-promyze-helper ${isOpen ? 'hide-helper' : 'show-helper'}`}
                    onClick={handleOpen}>{t('PROMYZE_WHAT')}</button>
            </article>
            <div className={`explanation-side-menu-container ${isOpen ? 'show-helper' : 'hide-helper'}`}>
                <article className={`explanation-side-menu ${isOpen ? 'show-helper' : 'hide-helper'}`}>
                    <IconButton onClick={handleClose} className={'close-button'}>
                        <CloseIcon />
                    </IconButton>
                    <h2 className={'title'}>{t('WHAT_IS_PROMYZE')}</h2>
                    <div className={'explanation-content'}>
                        <section className={'tutorial-video'}>
                            <iframe width='400' height='225' src='https://www.youtube-nocookie.com/embed/mY2PMccHxAM?rel=0'
                                title='YouTube video player' frameBorder='0'
                                allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen></iframe>
                        </section>
                        <p className={'explanation-text'}>
                            <Trans i18nKey={'PROMYZE_WHAT_EXPLANATION'}>
                                <strong></strong>
                            </Trans>
                        </p>
                        <section className={'get-promyze'}>
                            <a href='https://promyze.com' target='_blank' rel='noopener noreferrer' className={'get-promyze-button'}>{t('GET_PROMYZE')}</a>
                            <p className={'for-open-source'}>{t('FREE_FOR_OPEN_SOURCE')}</p>
                        </section>
                        <section className={'platforms-availability'}>
                            <h3 className={'available-on'}>{t('AVAILABLE_ON')}</h3>
                            <div className={'platforms'}>
                                {platforms.map((platform) => (
                                    <article className={'platform'} key={platform.name}>
                                        <div className={'icon-container'}>
                                            <img src={platform.icon} alt={platform.name} className={'platform-icon'} />
                                        </div>
                                        <p className={'platform-name'}>{platform.name}</p>
                                    </article>
                                ))
                                }
                            </div>
                        </section>
                    </div>
                </article>
            </div>
        </section>
    );
};

export default WhatIsPromyze;
