import { GATEWAYS } from '../../../../../corelogic/gateways/gatewaysUtils';
import { useState } from 'react';
import { Catalog } from '../../../../../domain/catalog/catalog';
import { User } from '../../../../../domain/user/user';

export function useUserInformation(selectedUser: User) {
    const catalogGateway = GATEWAYS.getCatalogGateway();
    const [catalogs, setCatalogs] = useState<Catalog[]>([]);

    const setCatalogsForUserEffect = () => {
        catalogGateway.getCatalogsForUser(selectedUser._id).then(setCatalogs);
    };

    return { catalogs, setCatalogsForUser: setCatalogsForUserEffect };
}
