import React from 'react';
import { useTranslation } from 'react-i18next';

import './practiceCard.scss';

import IAddToCartButton from '../../buttons/AddToCartButton/AddToCartButton';
import ICategories from '../../categories/categories';

import { Practice, isPracticeSearchedType } from '../../../../../domain/practice/practice';
import { cartContains } from '../../../../../corelogic/usecases/cart/cartContains/cartContains';
import { Done } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { getDependencies } from '../../../../../corelogic/redux/store';
import { ID } from '../../../../../domain/shared';
import { GATEWAYS } from '../../../../../corelogic/gateways/gatewaysUtils';

export type IPracticeCardProps = {
    practice: Practice;
    displaySearched?: boolean;
    catalogId?: ID;
};

function IPracticeCard({ practice, displaySearched, catalogId }: IPracticeCardProps) {
    const { t } = useTranslation();

    const isPracticeSearched = () => displaySearched && isPracticeSearchedType(practice) && practice.isSearched;

    const navigateToPractice = async () => {
        const practiceGateway = GATEWAYS.getCatalogGateway();
        if (!catalogId) {
            catalogId = (await practiceGateway.getCatalogFromPracticeId(practice._id))._id;
        }
        getDependencies().navigationService.navigate(`/catalog/${catalogId}/${practice._id}`);
    };

    return (
        <Badge
            invisible={!cartContains(practice)}
            badgeContent={<Done />}
            color={'success'}
            className="catalog-card-badge"
        >
            <div className={`card practice-card ${isPracticeSearched() ? 'searched' : ''}`}>
                <div className="practice-name">{practice.name}</div>
                <div className="practice-information">
                    <ICategories categories={practice.categories} />
                </div>
                <div className="practice-actions">
                    <button className="hub-button small" type="button" onClick={navigateToPractice}>
                        {t('OPEN_PRACTICE_BUTTON')}
                    </button>
                    <IAddToCartButton element={practice} />
                </div>
            </div>
        </Badge>
    );
}

export default IPracticeCard;
