import React from 'react';
import './socialLinkEdition.scss';
import { SocialPlatforms, User } from '../../../../../domain/user/user';
import { Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSocialLinkEdition } from './socialLinkEdition.logic';

type ISocialLinkEditionProps = {
    user: User;
    deleteLink: (platform: SocialPlatforms) => void;
};

export function ISocialLinkEdition({ user: { socialLinks }, deleteLink }: ISocialLinkEditionProps) {
    const { t } = useTranslation();
    const {
        isAdding,
        setIsAdding,
        platformList,
        deleteSocialLink,
        addLink,
        socialLinkInputRef,
        socialLinkSelectRef,
        onKeyDown,
    } = useSocialLinkEdition(socialLinks ?? [], deleteLink);

    return (
        <div className="social-edition">
            <div className="add-social-link">
                {isAdding ? (
                    <div className="add-social-link-form">
                        <div className="form-input-container">
                            <select name="platform" className="social-link-platforms" ref={socialLinkSelectRef}>
                                {platformList.map((platform, index) => (
                                    <option key={index} value={platform}>
                                        {platform}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-input-container">
                            <input
                                type="text"
                                className="social-link-input"
                                ref={socialLinkInputRef}
                                onKeyDown={onKeyDown}
                            />
                        </div>

                        <div className="form-input-container">
                            <button className="hub-button success" type="button" onClick={addLink}>
                                {t('ADD_BUTTON')}
                            </button>
                        </div>
                        <div className="form-input-container">
                            <button className="hub-button error" type="button" onClick={() => setIsAdding(false)}>
                                {t('CANCEL_BUTTON')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <button
                        className="hub-button"
                        type="button"
                        onClick={() => setIsAdding(true)}
                        disabled={platformList.length === 0}
                    >
                        +
                    </button>
                )}
            </div>
            {socialLinks?.map(({ platform }) => (
                <Badge
                    badgeContent={
                        <button
                            className="hub-button outline-filled delete-social-link-button"
                            type="button"
                            onClick={() => deleteSocialLink(platform)}
                        >
                            X
                        </button>
                    }
                    key={platform}
                    overlap={'circular'}
                >
                    <a
                        className="social-image-container"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={socialLinks?.find((social) => social.platform === platform)?.link}
                    >
                        <img src={`/images/socials/${platform}.svg`} alt="" className="social" />
                    </a>
                </Badge>
            ))}
        </div>
    );
}
