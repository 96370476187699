import React, { useEffect } from 'react';

import { User } from '../../../../../domain/user/user';

import { ISocialButtons } from '../../buttons/SocialButtons/SocialButtons';
import { IUserCategories } from '../userCategories/UserCategories';
import ICatalogCard from '../../cards/catalogCard/catalogCard';
import { IUserPageButtons } from '../userPageButtons/userPageButtons';

import { useUserInformation } from './userInformation.logic';

import './userInformation.scss';

type IUserInformationProps = {
    selectedUser: User;
    setIsEditing: (isEditing: boolean) => void;
};

export function IUserInformation({ selectedUser, setIsEditing }: IUserInformationProps) {
    const { catalogs, setCatalogsForUser } = useUserInformation(selectedUser);

    useEffect(setCatalogsForUser, [selectedUser]);

    return (
        <div className="user-information">
            <ISocialButtons user={selectedUser} />
            <div className="user-profile-picture-container">
                <img src="images/face.svg" alt="" className="user-profile-picture" />
            </div>
            <div className="user-name-container">
                <h1 className="user-name">{selectedUser.name}</h1>
            </div>
            <IUserCategories categories={selectedUser.categories ?? []} />
            <div className="description">{selectedUser.details}</div>
            {catalogs && catalogs.map ? (
                <div className="catalog-container">
                    {catalogs.map((catalog, index) => (
                        <ICatalogCard catalog={catalog} key={index} />
                    ))}
                </div>
            ) : null}
            <IUserPageButtons selectedUser={selectedUser} isEditing={false} setIsEditing={setIsEditing} />
        </div>
    );
}
