import React from 'react';
import './addCategoryForm.scss';
import { useAddCategoryForm } from './addCategoryForm.logic';
import { useTranslation } from 'react-i18next';

type IAddCategoryFormProps = {
    isAddCategoryFormOpen: boolean;
    setIsAddCategoryFormOpen: (isOpen: boolean) => void;
    addCategory: (category: string) => void;
};

export function IAddCategoryForm({
    isAddCategoryFormOpen,
    setIsAddCategoryFormOpen,
    addCategory,
}: IAddCategoryFormProps) {
    const { t } = useTranslation();
    const { onKeyDown, categoryInputRef, handleCategorySubmit } = useAddCategoryForm(
        addCategory,
        setIsAddCategoryFormOpen,
    );

    return (
        <div className="add-category-container">
            {isAddCategoryFormOpen ? (
                <div className="add-category">
                    <input
                        type="text"
                        className="add-category-input"
                        ref={categoryInputRef}
                        placeholder={t('CATEGORY_INPUT_PLACEHOLDER')}
                        onKeyDown={(event) => onKeyDown(event)}
                    />
                    <div className="add-button-container">
                        <button className="hub-button success" type="button" onClick={handleCategorySubmit}>
                            {t('ADD_BUTTON')}
                        </button>
                    </div>
                    <div className="cancel-button-container">
                        <button
                            className="hub-button error"
                            type="button"
                            onClick={() => setIsAddCategoryFormOpen(false)}
                        >
                            {t('CANCEL_BUTTON')}
                        </button>
                    </div>
                </div>
            ) : (
                <button className="hub-button" type="button" onClick={() => setIsAddCategoryFormOpen(true)}>
                    {t('ADD_CATEGORY_BUTTON')}
                </button>
            )}
        </div>
    );
}
