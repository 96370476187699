import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useAuthInfo } from '@propelauth/react';


import { closeCatalog, setCatalog } from '../../../../corelogic/usecases/catalog/viewCatalog/openCatalog';
import { deleteCatalog } from '../../../../corelogic/usecases/catalog/deleteCatalog/deleteCatalog';
import { selectUser } from '../../../../corelogic/redux/slices/user/user.selector';
import { getSelectedCatalog } from '../../../../corelogic/redux/slices/catalog/catalog.selector';

import { isCatalogForSearch } from '../../../../domain/catalog/catalog';
import { ID } from '../../../../domain/shared';
import { GATEWAYS } from '../../../../corelogic/gateways/gatewaysUtils';
import { getSelectedPractice } from '../../../../corelogic/redux/slices/practice/practice.selector';
import { Toaster } from '../../../../utils/toaster';
import { closePractice, viewPractice } from '../../../../corelogic/usecases/practice/viewPractice/viewPractice';
import { getDependencies } from '../../../../corelogic/redux/store';
import { useParams } from 'react-router-dom';
import { translate } from '../../../secondary/services/translation.service';

export function useCatalogPage() {
    //const { isAuthenticated } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn || false;
    const { catalogId, practiceId } = useParams();
    const selectedCatalog = useSelector(getSelectedCatalog);
    const selectedPractice = useSelector(getSelectedPractice);
    const user = useSelector(selectUser);
    const [canDelete, setCanDelete] = useState(false);
    const catalogGateway = GATEWAYS.getCatalogGateway();
    const practiceGateway = GATEWAYS.getPracticeGateway();

    const deleteSelectedCatalog = () => {
        if (selectedCatalog) {
            deleteCatalog(selectedCatalog);
            closeCatalog();
        }
    };

    const canDeleteEffect = () => {
        const ownerId = selectedCatalog?.owner?.id;
        setCanDelete(isAuthenticated && user?._id === ownerId);
    };

    const gatherCatalogFromId = async (catalogId: ID) => {
        const catalog = await catalogGateway.getCatalogById(catalogId);
        catalog.practices = catalog.practices.sort((a, b) => a.name.localeCompare(b.name));
        setCatalog(catalog);
    };

    const gatherPracticeFromId = async (practiceId: ID) => {
        return await practiceGateway.getPracticeDetails(practiceId);
    };

    const canDisplaySearch = () =>
        isCatalogForSearch(selectedCatalog) &&
        selectedCatalog.practicesCorrespondingToSearchCount !== selectedCatalog.practices.length;

    const catalogAndPracticeFetching = (): void => {
        if (!selectedCatalog && !catalogId) {
            setTimeout(closeCatalog, 0);
            return;
        }
        if (catalogId && selectedCatalog?._id !== catalogId) {
            gatherCatalogFromId(catalogId)
                .then()
                .catch(() => Toaster.error(translate('GET_ONE_CATALOG_FAILURE')));
        }
        if (practiceId && selectedPractice?._id !== practiceId) {
            gatherPracticeFromId(practiceId).then(viewPractice).catch(translate('GET_ONE_PRACTICE_FAILURE'));
        }
    };

    const closeModal = () => {
        closePractice();
        getDependencies().navigationService.navigate(`/catalog/${catalogId}`);
    };

    return {
        canDelete,
        selectedCatalog,
        selectedPractice,
        user,
        canDeleteEffect,
        canDisplaySearch,
        deleteSelectedCatalog,
        closeModal,
        catalogAndPracticeFetching,
        catalogId,
        practiceId,
    };
}
