import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './list.state';

import { buildDeleteCatalogReducers } from './asyncThunks/deleteCatalog.thunk';
import { buildImportCatalogReducers } from './asyncThunks/importCatalog.thunk';
import { buildLoadMoreCatalogsReducers } from './asyncThunks/loadMoreCatalogs.thunk';
import { buildLoadMorePracticesReducers } from './asyncThunks/loadMorePractices.thunk';
import { buildSearchCatalogsReducers } from './asyncThunks/searchCatalogs.thunk';
import { buildSearchPracticesReducers } from './asyncThunks/searchPractices.thunk';

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        initNewCatalogSearch: state => {
            state.catalogsPage = 1;
            state.hasMoreCatalogs = false;
        },
        initNewPracticeSearch: state => {
            state.practicesPage = 1;
            state.hasMorePractices = false;
        },
        setMoreCatalogSearch: state => {
            if (state.hasMoreCatalogs) {
                state.catalogsPage += 1;
            }
        },
        setMorePracticeSearch: state => {
            if (state.hasMorePractices) {
                state.practicesPage += 1;
            }
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
    },
    extraReducers: (builder) => {
        buildDeleteCatalogReducers(builder);
        buildImportCatalogReducers(builder);
        buildLoadMoreCatalogsReducers(builder);
        buildLoadMorePracticesReducers(builder);
        buildSearchCatalogsReducers(builder);
        buildSearchPracticesReducers(builder);
    },
});

export const {
    initNewCatalogSearch,
    initNewPracticeSearch,
    setMoreCatalogSearch,
    setMorePracticeSearch,
    setSearchText,
} = listSlice.actions;

export const listReducer = listSlice.reducer;
