import { useSelector } from 'react-redux';

import {
    getPracticeList,
    getHasMorePractices,
    getIsFetchingPractices,
} from '../../../../../corelogic/redux/slices/list/list.selector';

import { loadMorePractices } from '../../../../../corelogic/usecases/practice/loadMorePractices/loadMorePractices';

export function useFilters() {
    const practiceList = useSelector(getPracticeList);
    const hasMorePractices = useSelector(getHasMorePractices);
    const isFetchingPractices = useSelector(getIsFetchingPractices);

    const handleLoadMore = loadMorePractices;

    return { practiceList, hasMorePractices, isFetchingPractices, handleLoadMore };
}
