import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';
import { getState } from '../../../redux/store';

export function cartContains(element: Catalog | Practice): boolean {
    const cart = getState().cart.value;
    if ('practices' in element) {
        const catalog = element as Catalog;
        return cart.catalogList.find(({ _id }) => _id === catalog._id) !== undefined;
    } else {
        const practice = element as Practice;
        return cart.practiceList.find(({ _id }) => _id === practice._id) !== undefined;
    }
}
