export enum Env {
    REACT_APP_BACKEND_PORT = 'REACT_APP_BACKEND_PORT',
    REACT_APP_BACKEND_URL = 'REACT_APP_BACKEND_URL',
    REACT_APP_SENTRY_DSN = 'REACT_APP_SENTRY_DSN',
    REACT_APP_AUTH_URL = 'REACT_APP_FRONTEGG_BASE_URL',
    REACT_APP_FRONTEND_BASE_URL = 'REACT_APP_FRONTEND_BASE_URL',
    NODE_ENV = 'NODE_ENV',
}

export enum NodeEnv {
    DEBUG = 'DEBUG',
    PRODUCTION = 'PRODUCTION',
}

export function getEnv(envName: Env) {
    let allCapsEnvName = envName.toUpperCase();
    if (allCapsEnvName !== 'NODE_ENV' && !allCapsEnvName.startsWith('REACT_APP_')) {
        allCapsEnvName = `REACT_APP_${allCapsEnvName}`;
    }
    return process.env[allCapsEnvName];
}
