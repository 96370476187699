import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

export type CartState = {
    value: {
        catalogList: Catalog[];
        practiceList: Practice[];
    };
};

export const initialState: CartState = {
    value: {
        catalogList: [],
        practiceList: [],
    },
};
