import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../../corelogic/redux/slices/user/user.selector';
import { Comment } from '../../../../../../../domain/practice/Comment';
import { translate } from '../../../../../../secondary/services/translation.service';

const millisecondsInMinute = 60000;

const oneHour = 60;
const oneDay = oneHour * 24;
const oneMonth = oneDay * 30;
const oneYear = oneDay * 365;

export function usePracticeComment(comment: Comment) {
    const user = useSelector(selectUser);

    const now = Date.now();
    const timeSpentSinceCommentInMinutes = Math.floor((now - comment.timestamp) / millisecondsInMinute);

    const shouldDeleteButtonBeDisplayed = () => {
        return user?._id === comment.commenterId;
    };

    const computeTimeSpent = () => {
        if (timeSpentSinceCommentInMinutes < oneHour) {
            return translate('TIME_SPENT_MINUTES', { amountOfTime: timeSpentSinceCommentInMinutes });
        } else if (timeSpentSinceCommentInMinutes < oneDay) {
            return translate('TIME_SPENT_HOURS', { amountOfTime: timeSpentSinceCommentInMinutes });
        } else if (timeSpentSinceCommentInMinutes < oneMonth) {
            return translate('TIME_SPENT_DAYS', { amountOfTime: timeSpentSinceCommentInMinutes });
        } else if (timeSpentSinceCommentInMinutes < oneYear) {
            return translate('TIME_SPENT_MONTHS', { amountOfTime: timeSpentSinceCommentInMinutes });
        } else {
            return translate('TIME_SPENT_YEARS', { amountOfTime: timeSpentSinceCommentInMinutes });
        }
    };

    return { shouldDeleteButtonBeDisplayed, computeTimeSpent };
}
