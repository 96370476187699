import React, { useEffect } from 'react';

import './practiceRating.scss';
import { IconButton } from '@mui/material';
import { ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';

//import { useAuth } from '@frontegg/react-hooks';
import { useAuthInfo } from '@propelauth/react';

import { usePracticeRating } from './practiceRating.logic';
import { Practice } from '../../../../../../domain/practice/practice';
import { INeedLoginModal } from '../../needLoginModal/needLoginModal';

type IPracticeRatingProps = {
    practice: Practice;
};

export function IPracticeRating({ practice }: IPracticeRatingProps) {
    //const { isAuthenticated } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn || false;
    const {
        likeCount,
        dislikeCount,
        likeStatus,
        onClickLikeButton,
        onClickDislikeButton,
        isLoginModalOpen,
        setIsLoginModalOpen,
        practiceUpdateEffect,
    } = usePracticeRating(practice);

    useEffect(practiceUpdateEffect, [practice]);

    return (
        <div className="like-dislike-container">
            <div className="like-container">
                <IconButton className="like-button" onClick={() => onClickLikeButton(isAuthenticated)}>
                    {likeStatus.like ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                </IconButton>
                <div className="like-count">{likeCount}</div>
            </div>
            <div className="dislike-container">
                <IconButton className="dislike-button" onClick={() => onClickDislikeButton(isAuthenticated)}>
                    {likeStatus.dislike ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
                </IconButton>
                <div className="dislike-count">{dislikeCount}</div>
            </div>
            <INeedLoginModal setOpen={setIsLoginModalOpen} isOpen={isLoginModalOpen} />
        </div>
    );
}
