import { CraftTag } from '../practice/craftTag';
import { File } from '../practice/file';
import { Practice, PracticeImport, PracticeSearched } from '../practice/practice';
import { Owner } from '../user/user';
import { ID } from '../shared';

export type Catalog = {
    _id: ID;
    name: string;
    description: string;
    practices: Practice[];
    owner: Owner;
};

export type CatalogImport = {
    name: string;
    description: string;
    examples: CraftTag[];
    files: File[];
    practices: PracticeImport[];
};

export type CatalogForSearch = Omit<Catalog, 'practices'> & {
    practices: PracticeSearched[];
    practicesCorrespondingToSearchCount: number;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCatalogForSearch = (catalog: any): catalog is CatalogForSearch =>
    catalog && catalog.practicesCorrespondingToSearchCount !== undefined;

export type SearchCatalogs = {
    catalogs: CatalogForSearch[];
    hasMore: boolean;
    totalSearchNumber: number;
};
