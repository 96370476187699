import { getDispatch } from '../../../redux/store';

import { loadMoreCatalogs as loadMoreCatalogsAction } from '../../../redux/slices/list/asyncThunks/loadMoreCatalogs.thunk';
import { setMoreCatalogSearch as setMoreCatalogSearchAction } from '../../../redux/slices/list/list.slice';

export function loadMoreCatalogs() {
    const dispatch = getDispatch();
    dispatch(setMoreCatalogSearchAction());
    dispatch(loadMoreCatalogsAction());
}
