import React from 'react';

export function useAddCategoryForm(
    addCategory: (category: string) => void,
    setIsAddCategoryFormOpen: (isOpen: boolean) => void,
) {
    const categoryInputRef = React.createRef<HTMLInputElement>();

    const getInputValue = () => {
        const value = categoryInputRef.current?.value;
        if (value) {
            return value.trim();
        }
        return '';
    };

    const onKeyDown = ({ key }: { key: string }) => {
        const enterKeyCode = 'Enter';
        if (key === enterKeyCode) {
            handleCategorySubmit();
        }
    };

    const handleCategorySubmit = () => {
        const category = getInputValue();
        if (category) {
            addCategory(category);
            setIsAddCategoryFormOpen(false);
        }
    };

    return { onKeyDown, categoryInputRef, handleCategorySubmit };
}
