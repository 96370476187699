import { CatalogInMemoryGateway } from './CatalogInMemoryGateway';
import { CategoryInMemoryGateway } from './CategoryInMemoryGateway';
import { PracticeInMemoryGateway } from './PracticeInMemoryGateway';
import { UserInMemoryGateway } from './UserInMemory.gateway';

import { Gateways } from '../../../corelogic/gateways/Gateways.interface';
import { CatalogGateway } from '../../../corelogic/gateways/CatalogGateway.interface';
import { CategoryGateway } from '../../../corelogic/gateways/CategoryGateway.interface';
import { PracticeGateway } from '../../../corelogic/gateways/PracticeGateway.interface';
import { UserGateway } from '../../../corelogic/gateways/UserGateway.interface';
import { UserCartInMemoryGateway } from './UserCartInMemoryGateway';
import { UserCartGateway } from '../../../corelogic/gateways/UserCartGateway.interface';
import { CommentsGateway } from '../../../corelogic/gateways/CommentsGateway.interface';
import { CommentsInMemoryGateway } from './CommentsInMemoryGateway';

export class GatewaysInMemory implements Gateways {
    private readonly catalogGateway = new CatalogInMemoryGateway();
    private readonly userGateway = new UserInMemoryGateway();
    private readonly practiceGateway = new PracticeInMemoryGateway();
    private readonly categoriesGateway = new CategoryInMemoryGateway();
    private readonly userCartGateway = new UserCartInMemoryGateway();
    private readonly commentsGateway = new CommentsInMemoryGateway();

    getCatalogGateway(): CatalogGateway {
        return this.catalogGateway;
    }

    getUserGateway(): UserGateway {
        return this.userGateway;
    }

    getPracticeGateway(): PracticeGateway {
        return this.practiceGateway;
    }

    getCategoryGateway(): CategoryGateway {
        return this.categoriesGateway;
    }

    getUserCartGateway(): UserCartGateway {
        return this.userCartGateway;
    }

    getCommentsGateway(): CommentsGateway {
        return this.commentsGateway;
    }
}
