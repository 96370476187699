import React from 'react';
import './practiceComment.scss';
import { Link } from 'react-router-dom';
import { Comment } from '../../../../../../../domain/practice/Comment';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { usePracticeComment } from './practiceComment.logic';

type IPracticeCommentProps = {
    comment: Comment;
    deleteComment: (comment: Comment) => void;
};

export function IPracticeComment({ comment, deleteComment }: IPracticeCommentProps) {
    const { computeTimeSpent, shouldDeleteButtonBeDisplayed } = usePracticeComment(comment);

    return (
        <div className="comment-container">
            <div className="comment-header">
                <Link to={'/user/' + comment.commenterId} className="commenter-name">
                    {comment.commenterName}
                </Link>
                <span className="timestamp"> - {computeTimeSpent()}</span>
                {shouldDeleteButtonBeDisplayed() ? (
                    <IconButton onClick={() => deleteComment(comment)}>
                        <Delete color={'error'} />
                    </IconButton>
                ) : null}
            </div>
            <div className="comment-content">{comment.comment}</div>
        </div>
    );
}
