import { Catalog } from '../../../../../domain/catalog/catalog';
import { Practice } from '../../../../../domain/practice/practice';

import { Services } from '../../../../secondary/services/Services.service';
import { addCatalogToCart, addPracticeToCart } from '../../../../../corelogic/usecases/cart/addToCart/addToCart';
import {
    removeCatalogFromCart,
    removePracticeFromCart,
} from '../../../../../corelogic/usecases/cart/removeFromCart/removeFromCart';

export function useAddToCartLogic() {
    const addElementToCart = (element: Catalog | Practice) => {
        if (Services.instance().catalog.isCatalog(element)) {
            addCatalogToCart(element);
        } else {
            addPracticeToCart(element);
        }
    };

    const removeElementFromCart = (element: Catalog | Practice) => {
        if (Services.instance().catalog.isCatalog(element)) {
            removeCatalogFromCart(element);
        } else {
            removePracticeFromCart(element);
        }
    };
    return {
        addElementToCart,
        removeElementFromCart,
    };
}
