import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import IHeader from './adapters/primary/components/header/header';
import { DependenciesGatherer } from './corelogic/redux/store';
import './adapters/secondary/services/translation.service';
import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';

import { useDispatch, useSelector } from 'react-redux';
import { getUserByForeignID, setUserToNull } from './corelogic/redux/slices/user/user.slice';
import { axiosWrapper } from './adapters/secondary/axios/axios';
import { ToastContainer } from 'react-toastify';
import { ICartPage, ICatalogListPage, ICatalogPage, IImportPage } from './adapters/primary/pages';
import { selectUser } from './corelogic/redux/slices/user/user.selector';
import { loadCartFromDatabase } from './corelogic/usecases/cart/persistentCart/loadCartFromDatabase';
import { emptyCart } from './corelogic/usecases/cart/removeFromCart/removeFromCart';
import { getCart } from './corelogic/redux/slices/cart/cart.selector';
import { IUserPage } from './adapters/primary/pages/user/userPage';
import MainLayout from './adapters/primary/pages/layout/mainLayout';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;
    const isLoading = authInfo.loading;

    const user = authInfo.user;
    const accessToken = authInfo.accessToken;
    const {redirectToLoginPage } = useRedirectFunctions();

    const storeUser = useSelector(selectUser);
    const { catalogList, practiceList } = useSelector(getCart);
    const dispatch = useDispatch();

    useEffect(() => {
        
        const navigationService = DependenciesGatherer.getInstance().navigationService;
        const redirectItem = '_REDIRECT_AFTER_LOGIN_';
        const hasLoggedInItem = '_HAH_LOGGED_IN_';
        const hasLoggedIn = localStorage.getItem(hasLoggedInItem);
        if (hasLoggedIn && !isLoading) {
            if (!isAuthenticated) {
                localStorage.setItem(redirectItem, navigationService.location);
                redirectToLoginPage();
            } else {
                const originalRoute = localStorage.getItem(redirectItem);
                if (originalRoute) {
                    setTimeout(() => navigationService.navigate(originalRoute), 0);
                    localStorage.removeItem(redirectItem);
                }
            }
        }
    }, [isAuthenticated, redirectToLoginPage]);

    useEffect(() => {
        if (user) {
            axiosWrapper.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            dispatch(getUserByForeignID(user.userId));
        } else {
            delete axiosWrapper.defaults.headers.common.Authorization;
            dispatch(setUserToNull());
        }
    }, [user?.userId]);

    useEffect(() => {
        if (!storeUser) {
            emptyCart().then();
        } else if (catalogList.length === 0 && practiceList.length === 0) {
            loadCartFromDatabase();
        }
    }, [storeUser, catalogList.length, practiceList.length]);

    DependenciesGatherer.instantiateServices();
    return (
        <div className='App'>
            <IHeader />
            <h1 className='beta-sign'>Beta</h1>
            <MainLayout>
                <Routes>
                    <Route path='/' element={<ICatalogListPage />} />
                    <Route path='/import' element={<IImportPage />} />
                    <Route path='/list' element={<ICatalogListPage />} />
                    <Route path='/catalog/:catalogId/:practiceId' element={<ICatalogPage />} />
                    <Route path='/catalog/:catalogId' element={<ICatalogPage />} />
                    <Route path='/cart' element={<ICartPage />} />
                    <Route path='/user' element={<IUserPage />} />
                    <Route path='/user/:userId' element={<IUserPage />} />
                </Routes>
            </MainLayout>
            <ToastContainer />
        </div>
    );
}

export default App;
