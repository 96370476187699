import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthInfo, useRedirectFunctions, useLogoutFunction } from '@propelauth/react';

import { Badge, IconButton, Menu, MenuItem } from '@mui/material';

import './navbar.scss';

import { getCart } from '../../../../../corelogic/redux/slices/cart/cart.selector';
import { selectUser } from '../../../../../corelogic/redux/slices/user/user.selector';
import { KeyboardArrowDown, Login } from '@mui/icons-material';

export default function INavbar() {

    const { redirectToAccountPage, redirectToLoginPage } = useRedirectFunctions();
    const logoutFn = useLogoutFunction(); // (1);
    
    const { t } = useTranslation();
    //const { isAuthenticated, user } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;
    const user = authInfo.user;

    const currentUser = useSelector(selectUser);
    const cart = useSelector(getCart);

    const hasLoggedInItem = '_HAH_LOGGED_IN_';

    const [elementsNumber, setElementsNumber] = useState(cart.practiceList.length + cart.catalogList.length);
    useEffect(() => {
        setElementsNumber(cart.practiceList.length + cart.catalogList.length);
    }, [cart]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openUserMenuPopover = Boolean(anchorEl);
    const handleClickOnUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickContribute = () => {
        const contributeLink = 'https://docs.promyze.com/hub-of-practices/contribute-to-the-hub';
        window.open(contributeLink);
    };

    const login = () => {
        localStorage.setItem(hasLoggedInItem, 'true');
        redirectToLoginPage();
    };

    const logout = () => {
        localStorage.removeItem(hasLoggedInItem);
        logoutFn(true);
    };

    const displayProfilePortal = () => {
        redirectToAccountPage();
        handleClose();
    };

    return (
        <div className='nav-bar-container'>
            <div className='catalog-action-buttons'>
                <Link className='catalog-list-link link-button' to='/list'>
                    <h4>{t('LIST_LINK')}</h4>
                </Link>
                {isAuthenticated ? (
                    <Link className='import-link link-button' to={`/user/${currentUser?._id}`}>
                        <h4>{t('MY_CATALOGS_LINK')}</h4>
                    </Link>
                ) : null}


                <button className='catalog-list-button' type='button' onClick={onClickContribute}>
                    <h4>{t('CONTRIBUTE_BUTTON')}</h4>
                </button>
            </div>
            <div className='account-buttons'>
                {isAuthenticated ? (
                    <Link className='cart-link' to='/cart'>
                        <IconButton aria-label='cart'>
                            <Badge badgeContent={elementsNumber} color='secondary'>
                                <img src='/images/library.svg' alt='Library' className='library-pictogram' />
                            </Badge>
                        </IconButton>
                        <span className='cart-text'>{t('LIBRARY')}</span>
                    </Link>
                ) : null}
                {isAuthenticated ? (
                    <div className='manage-account-button-container'>
                        <button className='manage-account-button' type='button'
                            aria-controls={openUserMenuPopover ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={openUserMenuPopover ? 'true' : undefined}
                            onClick={handleClickOnUserMenu}
                        >
                            <img src={user?.pictureUrl} alt='' className='user-profile-picture' />
                            <h4>{user?.username}</h4>
                            <KeyboardArrowDown />
                        </button>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={openUserMenuPopover}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={displayProfilePortal}>{t('MANAGE_ACCOUNT')}</MenuItem>
                            <MenuItem onClick={logout}>{t('LOGOUT_LINK')}</MenuItem>
                        </Menu>
                    </div>

                ) : (
                    <button className='hub-button outline' type='button' onClick={login}>
                        <h4>{t('LOGIN_LINK')}</h4>
                        <Login className={'login-icon'}/>
                    </button>
                )}
            </div>
        </div>
    );
}
