import React from 'react';

import WhatIsPromyze from '../../components/whatIsPromyze/whatIsPromyze';

import './mainLayout.style.scss';

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <div className={'main-layout'}>
            <WhatIsPromyze />
            <section id={'scroll-parent'} className={'main-layout-content'}>
                {children}
            </section>
        </div>
    );
};


export default MainLayout;
