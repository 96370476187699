import { CatalogForSearch } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

export type ListState = {
    catalogs: CatalogForSearch[];
    practices: Practice[];
    search: string;
    catalogsPage: number;
    practicesPage: number;
    pageSize: number;
    catalogsTotalSearch: number;
    practicesTotalSearch: number;
    hasMoreCatalogs: boolean;
    hasMorePractices: boolean;
    isFetchingCatalogs: boolean;
    isFetchingPractices: boolean;
};

export const initialState: ListState = {
    catalogs: [],
    practices: [],
    search: '',
    catalogsPage: 1,
    practicesPage: 1,
    pageSize: 25,
    catalogsTotalSearch: 0,
    practicesTotalSearch: 0,
    hasMoreCatalogs: false,
    hasMorePractices: false,
    isFetchingCatalogs: true,
    isFetchingPractices: true,
};
