import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#2687ff',
        },
        secondary: {
            main: '#8477d3',
        },
        error: {
            main: '#ed5a59',
        },
        success: {
            main: '#00c897',
        },
        warning: {
            main: '#f5ab3d',
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#2687ff',
        },
        secondary: {
            main: '#8477d3',
        },
        error: {
            main: '#ed5a59',
        },
        success: {
            main: '#00c897',
        },
        warning: {
            main: '#f5ab3d',
        },
    },
});
