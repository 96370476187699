export type CategoryState = {
    isFetching: boolean;
    categoryList: { category: string, occurrence: number }[];
    selectedCategories: string[];
};

export const initialState: CategoryState = {
    isFetching: false,
    categoryList: [],
    selectedCategories: [],
};
