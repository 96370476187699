import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Checkbox, FormControlLabel } from '@mui/material';

import './filters.scss';
import { useFilters } from './filters.logic';

import { searchCatalogs } from '../../../../corelogic/usecases/catalog/searchCatalogs/searchCatalogs';
import { searchPractices } from '../../../../corelogic/usecases/practice/searchPractices/searchPractices';
import { getAllCategories } from '../../../../corelogic/usecases/category/getCategories/getCategories';

function IFilters() {
    const { t } = useTranslation();
    const {
        categoryList,
        isFetchingCategories,
        search,
        handleOnCategoryClicked,
        handleOnSearchTextChanged,
        isSelected,
    } = useFilters();

    useEffect(() => {
        getAllCategories();
        searchCatalogs();
        searchPractices();
    }, []);

    return (
        <section className="filters-container">
            <article className="search-input-container">
                <input
                    type="text"
                    className="promyze-search-input"
                    placeholder={t('SEARCH')}
                    value={search}
                    onChange={handleOnSearchTextChanged}
                />
            </article>

            <article className="search-categories-container">
                <h3 className="categories-filters-title">{t('FILTERS')}</h3>
                <div className="categories-filters-list">
                    {isFetchingCategories &&
                        <article className="categories-loader">
                            <CircularProgress />
                        </article>
                    }

                    {!isFetchingCategories && categoryList.map((category) => (
                        <div className="categories-filter-item" key={category.category}>
                            <FormControlLabel
                                className="promyze-checkbox"
                                control={<Checkbox defaultChecked={isSelected(category.category)} />}
                                label={`${category.category} (${category.occurrence})`}
                                onChange={(event) => handleOnCategoryClicked(category.category, (event.target as HTMLInputElement).checked)}
                            />
                        </div>
                    ))}
                </div>
            </article>
        </section>
    );
}

export default IFilters;
