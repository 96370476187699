import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../corelogic/redux/slices/user/user.selector';
import { dislikePractice, likePractice } from '../../../../../../corelogic/usecases/practice/likePractice/likePractice';
import { Toaster } from '../../../../../../utils/toaster';
import { translate } from '../../../../../secondary/services/translation.service';
import { Practice } from '../../../../../../domain/practice/practice';

export function usePracticeRating(practice: Practice) {
    const [likeCount, setLikeCount] = useState(practice.rating.likes.length);
    const [dislikeCount, setDislikeCount] = useState(practice.rating.dislikes.length);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const user = useSelector(selectUser);

    const userLikeStatus = () => {
        const userLikes = !!practice.rating.likes.find((id) => user?._id === id);
        const userDislikes = !!practice.rating.dislikes.find((id) => user?._id === id);
        return { like: userLikes, dislike: userDislikes };
    };

    const [likeStatus, setLikeStatus] = useState(userLikeStatus());

    const practiceUpdateEffect = () => {
        setLikeCount(practice.rating.likes.length);
        setDislikeCount(practice.rating.dislikes.length);
        setLikeStatus(userLikeStatus());
    };

    const backupCurrentValues = () => {
        return {
            likeStatusBefore: likeStatus.like,
            dislikeStatusBefore: likeStatus.dislike,
            likeCountBefore: likeCount,
            dislikeCountBefore: dislikeCount,
        };
    };

    const resetVales = (likeCount: number, dislikeCount: number, likeStatus: boolean, dislikeStatus: boolean) => {
        setLikeCount(likeCount);
        setDislikeCount(dislikeCount);
        setLikeStatus({ like: likeStatus, dislike: dislikeStatus });
    };

    const onClickLikeButton = (isAuthenticated: boolean) => {
        if (!isAuthenticated) {
            setIsLoginModalOpen(true);
            return;
        }
        if (!user) return;
        const { likeStatusBefore, dislikeCountBefore, dislikeStatusBefore, likeCountBefore } = backupCurrentValues();

        likeStatus.like ? setLikeCount(likeCount - 1) : setLikeCount(likeCount + 1);

        if (likeStatus.dislike) {
            setDislikeCount(dislikeCount - 1);
        }

        setLikeStatus({ like: !likeStatus.like, dislike: false });

        likePractice(practice._id)
            .then()
            .catch(() => {
                Toaster.error(translate('LIKE_ERROR_MESSAGE'));
                resetVales(likeCountBefore, dislikeCountBefore, likeStatusBefore, dislikeStatusBefore);
            });
    };

    const onClickDislikeButton = (isAuthenticated: boolean) => {
        if (!isAuthenticated) {
            setIsLoginModalOpen(true);
            return;
        }
        if (!user) return;
        const { likeStatusBefore, dislikeCountBefore, dislikeStatusBefore, likeCountBefore } = backupCurrentValues();

        likeStatus.dislike ? setDislikeCount(dislikeCount - 1) : setDislikeCount(dislikeCount + 1);

        if (likeStatus.like) {
            setLikeCount(likeCount - 1);
        }

        setLikeStatus({ like: false, dislike: !likeStatus.dislike });

        dislikePractice(practice._id)
            .then()
            .catch(() => {
                Toaster.error(translate('DISLIKE_ERROR_MESSAGE'));
                resetVales(likeCountBefore, dislikeCountBefore, likeStatusBefore, dislikeStatusBefore);
            });
    };

    return {
        onClickDislikeButton,
        onClickLikeButton,
        likeCount,
        dislikeCount,
        likeStatus,
        isLoginModalOpen,
        setIsLoginModalOpen,
        practiceUpdateEffect,
    };
}
