import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';
import { ListState } from '../list.state';

import { Catalog } from '../../../../../domain/catalog/catalog';

import { ID } from '../../../../../domain/shared';
import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

let toaster: Toaster;
export const deleteCatalog = createAsyncThunk(
    `${baseName}/deleteCatalog`,
    async ({ catalog, userId }: { catalog: Catalog; userId: ID }) => {
        toaster = Toaster.loading(translate('DELETE_LOADING_TOASTER'));
        await catalogGateway.deleteCatalog(catalog._id, userId);
        return catalog._id;
    },
);

export const buildDeleteCatalogReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(deleteCatalog.fulfilled, (state, action) => {
        toaster.updateSuccess(translate('DELETE_SUCCESS_TOASTER'));
        state.catalogs = state.catalogs.filter(({ _id }) => _id !== action.payload);
    });

    builder.addCase(deleteCatalog.rejected, () => {
        toaster.updateError(translate('DELETE_FAILURE_TOASTER'));
    });
};
