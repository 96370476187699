import React from 'react';
import { useTranslation } from 'react-i18next';

import './catalogCard.scss';

import IAddToCartButton from '../../buttons/AddToCartButton/AddToCartButton';

import { Catalog } from '../../../../../domain/catalog/catalog';
import { openCatalog } from '../../../../../corelogic/usecases/catalog/viewCatalog/openCatalog';
import { useCatalogCard } from './catalogCard.logic';
import ICategories from '../../categories/categories';
import { Link } from 'react-router-dom';
import { Badge } from '@mui/material';
import { cartContains } from '../../../../../corelogic/usecases/cart/cartContains/cartContains';
import { Done } from '@mui/icons-material';

export type ICatalogCardProps = {
    catalog: Catalog;
};

function ICatalogCard({ catalog }: ICatalogCardProps) {
    const { t } = useTranslation();
    const { getPracticesCountText, getCatalogsCategories } = useCatalogCard();
    const categories = getCatalogsCategories(catalog);

    return (
        <Badge
            invisible={!cartContains(catalog)}
            badgeContent={<Done />}
            color={'success'}
            className="catalog-card-badge"
        >
            <div className="card catalog-card">
                <div className="catalog-name">{catalog.name}</div>
                <div className="catalog-information">
                    <div className="practice-number">{getPracticesCountText(catalog)}</div>
                    <div className="category-container">
                        <ICategories categories={categories} />
                    </div>
                </div>
                <div className="catalog-actions">
                    <Link className="user-name" to={`/user/${catalog.owner.id}`}>
                        {catalog.owner.name}
                    </Link>
                    <div className="catalog-action-buttons">
                        <button
                            className="hub-button small view-catalog-button"
                            type="button"
                            onClick={() => openCatalog(catalog)}
                        >
                            {t('OPEN_CATALOG')}
                        </button>
                        <IAddToCartButton element={catalog} />
                    </div>
                </div>
            </div>
        </Badge>
    );
}

export default ICatalogCard;
