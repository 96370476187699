import React from 'react';
import './practiceSwitchingButtons.scss';
import { IconButton } from '@mui/material';
import {
    isFirstPractice,
    isLastPractice,
    nextPractice,
    previousPractice,
} from '../../../../../../corelogic/usecases/practice/practiceNavigation/practiceNavigation';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Catalog } from '../../../../../../domain/catalog/catalog';
import { Practice } from '../../../../../../domain/practice/practice';

type IPracticeSwitchingButtonsProps = {
    catalog: Catalog;
    practice: Practice;
};

export function IPracticeSwitchingButtons({ catalog, practice }: IPracticeSwitchingButtonsProps) {
    return (
        <div className="practice-switching-button-container">
            <IconButton
                className="next-practice-button"
                disabled={isLastPractice(practice, catalog)}
                onClick={() => nextPractice(practice, catalog)}
            >
                <ArrowForward />
            </IconButton>
            <IconButton
                className="previous-practice-button"
                disabled={isFirstPractice(practice, catalog)}
                onClick={() => previousPractice(practice, catalog)}
            >
                <ArrowBack />
            </IconButton>
        </div>
    );
}
