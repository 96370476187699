import { getDispatch } from '../../../redux/store';

import { initNewPracticeSearch as initNewPracticeSearchAction } from '../../../redux/slices/list/list.slice';
import { searchPractices as searchPracticesAction } from '../../../redux/slices/list/asyncThunks/searchPractices.thunk';

export function searchPractices() {
    const dispatch = getDispatch();
    dispatch(initNewPracticeSearchAction());
    dispatch(searchPracticesAction());
}
