import { axiosWrapper } from '../axios/axios';

import { Env, getEnv } from '../../../utils/env';

export class APIService {
    private readonly baseUrl;

    constructor() {
        this.baseUrl = `${getEnv(Env.REACT_APP_BACKEND_URL)}:${getEnv(Env.REACT_APP_BACKEND_PORT)}/api`;
    }

    get<Return>(url: string): Promise<Return> {
        return axiosWrapper.get(`${this.baseUrl}/${url}`);
    }

    post<Payload, Return>(url: string, payload?: Payload): Promise<Return> {
        return axiosWrapper.post(`${this.baseUrl}/${url}`, payload);
    }

    put<Payload, Return>(url: string, payload?: Payload): Promise<Return> {
        return axiosWrapper.put(`${this.baseUrl}/${url}`, payload);
    }

    delete<Return>(url: string): Promise<Return> {
        return axiosWrapper.delete(`${this.baseUrl}/${url}`);
    }
}
