import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCatalogPage } from './catalogPage.logic';

import { IAddToCartButtonFull } from '../../components/buttons/AddToCartButtonFull/AddToCartButtonFull';

import { closeCatalog } from '../../../../corelogic/usecases/catalog/viewCatalog/openCatalog';
import { getCart } from '../../../../corelogic/redux/slices/cart/cart.selector';
import { IconButton } from '@mui/material';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import { IPracticeDisplay } from './practiceDisplay/practiceDisplay';
import { IValidationModal } from '../../components/modals/validationModal/validationModal';
import { Link } from 'react-router-dom';
import { IPracticeModal } from '../../components/modals/practiceModal/practiceModal';
import MarkdownViewer from '../../components/shared/markdownViewer/markdownViewer';

import './catalogPage.scss';

export function ICatalogPage() {
    const { t } = useTranslation();
    const {
        canDelete,
        selectedCatalog,
        selectedPractice,
        user,
        canDeleteEffect,
        canDisplaySearch,
        deleteSelectedCatalog,
        catalogAndPracticeFetching,
        closeModal,
        catalogId,
        practiceId,
    } = useCatalogPage();

    const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
    useSelector(getCart);

    useEffect(() => catalogAndPracticeFetching(), [catalogId, practiceId]);
    useEffect(canDeleteEffect, [user, selectedCatalog]);

    return (
        <div className='main-page-container'>
            {selectedCatalog ? (
                <div>
                    <div className='page-header'>
                        <div className='back-button-container'>
                            <IconButton onClick={closeCatalog}>
                                <ArrowCircleLeftOutlined fontSize={'large'} />
                            </IconButton>
                        </div>
                        <h2>{selectedCatalog.name}</h2>
                        <h4 className='catalog-owner'>
                            {t('BY')}
                            <Link to={`/user/${selectedCatalog.owner.id}`} className='user-link'>
                                {selectedCatalog.owner.name}
                            </Link>
                        </h4>
                    </div>
                    <div
                        className='catalog-description'
                    >
                        <MarkdownViewer rawContent={selectedCatalog.description} />
                    </div>
                    {canDelete ? (
                        <button
                            className='hub-button error delete-button'
                            onClick={() => setIsValidationModalOpen(true)}
                        >
                            {t('DELETE_BUTTON_TEXT')}
                        </button>
                    ) : (
                        <IAddToCartButtonFull catalog={selectedCatalog} />
                    )}

                    <IPracticeDisplay
                        practices={selectedCatalog?.practices}
                        canDisplaySearch={canDisplaySearch}
                        catalogId={catalogId}
                    />

                    {selectedPractice ? (
                        <IPracticeModal isOpen={true} practice={selectedPractice} closeModal={closeModal} />
                    ) : null}
                </div>
            ) : null}
            <IValidationModal
                onValidation={deleteSelectedCatalog}
                isOpen={isValidationModalOpen}
                setOpen={setIsValidationModalOpen}
            >
                {t('DELETE_CONFIRMATION_MESSAGE')}
            </IValidationModal>
        </div>
    );
}
