import { Practice } from '../../../../domain/practice/practice';
import { getDispatch } from '../../../redux/store';
import {
    viewPractice as viewPracticeAction,
    closePractice as closePracticeAction,
} from '../../../redux/slices/practice/practice.slice';

export function viewPractice(practice: Practice) {
    const dispatch = getDispatch();
    dispatch(viewPracticeAction(practice));
}

export function closePractice() {
    const dispatch = getDispatch();
    dispatch(closePracticeAction());
}
