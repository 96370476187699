import { Catalog, CatalogForSearch, isCatalogForSearch } from '../../../../../domain/catalog/catalog';
import { translate } from '../../../../secondary/services/translation.service';

export function useCatalogCard() {
    const getPracticesCountText = (catalog: Catalog) => {
        if (isCatalogForSearch(catalog)) {
            return getPracticeCountTextForCatalogForSearch(catalog);
        } else {
            return getPracticeCountTextForNormaCatalog(catalog);
        }
    };

    const getPracticeCountTextForCatalogForSearch = (catalog: CatalogForSearch) => {
        const practiceCount = catalog.practices.length;
        const searchedPracticeCount = catalog.practicesCorrespondingToSearchCount;
        if (searchedPracticeCount !== practiceCount) {
            return translate('CATALOG_CARD_PRACTICE_NUMBER_PARTIAL', {
                count: practiceCount,
                searched: searchedPracticeCount,
            });
        } else {
            return translate('CATALOG_CARD_PRACTICE_NUMBER', { count: practiceCount });
        }
    };

    const getPracticeCountTextForNormaCatalog = (catalog: Catalog) => {
        return translate('CATALOG_CARD_PRACTICE_NUMBER', { count: catalog.practices.length });
    };

    const getCatalogsCategories = (catalog: Catalog) => {
        const categorySet: Set<string> = new Set<string>();
        catalog.practices.map((practice) => practice.categories.map((category) => categorySet.add(category)));
        return Array.from(categorySet);
    };

    return { getPracticesCountText, getCatalogsCategories };
}
