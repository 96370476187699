import { useState } from 'react';
import { PracticeFull } from '../../../../../../domain/practice/practice';
import { LineWidget } from 'codemirror';
import { Example } from '../../../../../../domain/practice/example';

export function useExampleInformation(practiceFull: PracticeFull) {
    const [current, setCurrent] = useState<{ positive: number; negative: number }>({ positive: 0, negative: 0 });
    const [lineWidget, setWidget] = useState<{ positive?: LineWidget; negative?: LineWidget }>({});
    const [example, setExample] = useState<{ positive?: Example; negative?: Example }>({});

    const setCurrentPositive = (positive: number) => setCurrent({ positive, negative: current.negative });
    const setCurrentNegative = (negative: number) => setCurrent({ positive: current.negative, negative });

    const setExamplePositive = (positive: Example) => setExample({ positive, negative: example.negative });
    const setExampleNegative = (negative: Example) => setExample({ positive: example.negative, negative });

    const setPositiveWidget = (positive: LineWidget) => setWidget({ positive, negative: lineWidget.negative });
    const setNegativeWidget = (negative: LineWidget) => setWidget({ positive: lineWidget.positive, negative });

    const [examples, setExamples] = useState<{ positives: Example[]; negatives: Example[] }>({
        positives: [],
        negatives: [],
    });

    const hasPositives = () => examples.positives.length > 0;
    const hasNegatives = () => {
        return examples.negatives.length > 0;
    };

    const hasNextPositiveFile = () => current.positive < examples.positives.length - 1;
    const hasNextNegativeFile = () => current.negative < examples.negatives.length - 1;
    const hasPreviousPositiveFile = () => current.positive > 0;
    const hasPreviousNegativeFile = () => current.negative > 0;

    const nextPositiveFile = () => {
        if (current.positive < examples.positives.length - 1) {
            lineWidget.positive?.clear();
            setCurrentPositive(current.positive + 1);
            setExamplePositive(examples.positives[current.positive + 1]);
        }
    };

    const nextNegativeFile = () => {
        if (current.negative < examples.negatives.length - 1) {
            lineWidget.negative?.clear();
            setCurrentNegative(current.negative + 1);
            setExampleNegative(examples.negatives[current.negative + 1]);
        }
    };

    const previousPositiveFile = () => {
        if (current.positive > 0) {
            lineWidget.positive?.clear();
            setCurrentPositive(current.positive - 1);
            setExamplePositive(examples.positives[current.positive - 1]);
        }
    };

    const previousNegativeFile = () => {
        if (current.negative > 0) {
            lineWidget.negative?.clear();
            setCurrentNegative(current.negative - 1);
            setExampleNegative(examples.negatives[current.negative - 1]);
        }
    };

    const initExample = () => {
        if (!practiceFull.examples.length) return;
        const positives = practiceFull.examples.filter((e) => e.isPositive);
        const negatives = practiceFull.examples.filter((e) => !e.isPositive);
        setExamples({
            positives,
            negatives,
        });
        setExample({
            positive: positives.length > 0 ? positives[0] : undefined,
            negative: negatives.length > 0 ? negatives[0] : undefined,
        });
    };

    return {
        hasNextNegativeFile,
        hasNextPositiveFile,
        hasPreviousNegativeFile,
        hasPreviousPositiveFile,
        nextPositiveFile,
        nextNegativeFile,
        previousPositiveFile,
        previousNegativeFile,
        hasNegatives,
        hasPositives,
        example,
        setPositiveWidget,
        setNegativeWidget,
        initExample,
    };
}
