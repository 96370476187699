import React from 'react';
import './needLoginModal.scss';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRedirectFunctions } from '@propelauth/react';

type INeedLoginModalProps = {
    isOpen: boolean;
    setOpen: (open: boolean) => void;
};

export function INeedLoginModal({ isOpen, setOpen }: INeedLoginModalProps) {
    const { t } = useTranslation();
    const { redirectToLoginPage } = useRedirectFunctions();
    return (
        <Modal open={isOpen} onClose={() => setOpen(false)} className="modal">
            <div className="login-needed-modal card">
                <h1 className="login-needed-title">{t('LOGIN_NEEDED_TITLE')}</h1>
                <p className="login-needed-text">{t('LOGIN_NEEDED_TEXT')}</p>
                <div className="button-container">
                    <button className="hub-button" type="button" onClick={() => redirectToLoginPage()}>
                        {t('LOGIN_LINK')}
                    </button>
                    <button className="hub-button error" type="button" onClick={() => setOpen(false)}>
                        {t('CANCEL_BUTTON')}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
