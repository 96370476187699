import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@mui/material';

import './listPage.scss';

import { ElementType, useListPage } from './listPage.logic';

import IFilters from '../../components/filters/filters';
import { ICatalogList } from '../../components/lists/catalogList/catalogList';
import { IPracticeList } from '../../components/lists/practiceList/practiceList';

export function ICatalogListPage() {
    const { t } = useTranslation();
    const { catalogsTotalSearch, practicesTotalSearch, selectedElement, selectPractice, selectCatalog } = useListPage();

    return (
        <div className="main-page-container">
            <header className="page-header">
                <h1 className="page-title">{t('PRACTICES_EXPLORATION_TITLE')}</h1>
                <p className="page-subtitle">{t('PRACTICES_EXPLORATION_SUBTITLE')}</p>
            </header>
            <section className="page-body practices-list-page">
                <IFilters />
                <section className="practices-list-container">
                    <section className="display-mode-selector">
                        <div className="select-catalog-container">
                            <Badge badgeContent={catalogsTotalSearch} max={999} color="primary">
                                <button
                                    className={`hub-button primary ${
                                        selectedElement === ElementType.Catalog ? '' : 'outline'
                                    }`}
                                    onClick={selectCatalog}
                                >
                                    {t('CATALOGUES')}
                                </button>
                            </Badge>
                        </div>
                        <div className="select-practices-container">
                            <Badge badgeContent={practicesTotalSearch} max={999} color="secondary">
                                <button
                                    className={`hub-button secondary ${
                                        selectedElement === ElementType.Catalog ? 'outline' : ''
                                    }`}
                                    onClick={selectPractice}
                                >
                                    {t('PRACTICES')}
                                </button>
                            </Badge>
                        </div>
                    </section>
                    {selectedElement === ElementType.Catalog ? <ICatalogList /> : <IPracticeList />}
                </section>
            </section>
        </div>
    );
}
