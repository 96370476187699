import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useAuth } from '@frontegg/react-hooks';
import { useAuthInfo } from '@propelauth/react';

import './AddToCartButtonFull.scss';
import { Add, Done } from '@mui/icons-material';

import { INeedLoginModal } from '../../modals/needLoginModal/needLoginModal';

import { Catalog } from '../../../../../domain/catalog/catalog';

import { cartContains } from '../../../../../corelogic/usecases/cart/cartContains/cartContains';
import { removeCatalogFromCart } from '../../../../../corelogic/usecases/cart/removeFromCart/removeFromCart';
import { addCatalogToCart } from '../../../../../corelogic/usecases/cart/addToCart/addToCart';

type IAddToCartButtonFullProps = {
    catalog: Catalog;
};

export function IAddToCartButtonFull({ catalog }: IAddToCartButtonFullProps) {
    const { t } = useTranslation();
    //const { isAuthenticated } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    return !isAuthenticated ? (
        <div>
            <button className="hub-button active" type="button" onClick={() => setIsLoginModalOpen(true)}>
                <Add />
                <p className="cart-action-button-text">{t('ADD_TO_CART_BUTTON_TEXT')}</p>
            </button>
            <INeedLoginModal setOpen={setIsLoginModalOpen} isOpen={isLoginModalOpen} />
        </div>
    ) : cartContains(catalog) ? (
        <button
            className="hub-button success cart-action-button"
            type="button"
            onClick={() => removeCatalogFromCart(catalog)}
        >
            <Done />
            <p className="cart-action-button-text">{t('REMOVE_FROM_CART_BUTTON_TEXT')}</p>
        </button>
    ) : (
        <button className="hub-button cart-action-button" type="button" onClick={() => addCatalogToCart(catalog)}>
            <Add />
            <p className="cart-action-button-text">{t('ADD_TO_CART_BUTTON_TEXT')}</p>
        </button>
    );
}
