import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';

import { ListState } from '../list.state';
import { RootState } from '../../../store';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Toaster } from '../../../../../utils/toaster';

export const searchCatalogs = createAsyncThunk(`${baseName}/searchCatalogs`, async (_, { getState }) => {
    const state = getState() as RootState;
    const { category: { selectedCategories }, list: { search, catalogsPage, pageSize } } = state;

    return await catalogGateway.search(search, selectedCategories, catalogsPage, pageSize);
});

export const buildSearchCatalogsReducers = (builder: ActionReducerMapBuilder<ListState>) => {
    builder.addCase(searchCatalogs.pending, state => {
        state.isFetchingCatalogs = true;
    });

    builder.addCase(searchCatalogs.fulfilled, (state, action) => {
        state.catalogs = [...action.payload.catalogs];
        state.catalogsTotalSearch = action.payload.totalSearchNumber;
        state.hasMoreCatalogs = action.payload.hasMore;
        state.isFetchingCatalogs = false;
    });

    builder.addCase(searchCatalogs.rejected, state => {
        state.isFetchingCatalogs = false;
        Toaster.error(translate('SEARCH_CATALOGS_FAILURE'));
    });
};
