import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

import { exportCatalog as exportCatalogAction } from '../../../redux/slices/cart/asyncThunks/exportCatalog.thunk';
import { exportPersonalisedCatalog as exportPersonalisedCatalogAction } from '../../../redux/slices/cart/asyncThunks/exportPersonalisedCatalog.thunk';
import { getDispatch, getState } from '../../../redux/store';

export function exportCatalog(catalog: Catalog) {
    const dispatch = getDispatch();
    const userId = getState().user.user?._id ?? '';
    dispatch(exportCatalogAction({catalog, userId}));
}

export function exportPersonalisedCatalogWithPractices(practices: Practice[]) {
    const dispatch = getDispatch();
    const userId = getState().user.user?._id ?? '';
    dispatch(exportPersonalisedCatalogAction({practices, userId}));
}
