import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './practice.state';
import { Practice } from '../../../../domain/practice/practice';

export const practiceSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        viewPractice: (state, action: PayloadAction<Practice>) => {
            state.selectedPractice = action.payload;
        },
        closePractice: (state) => {
            state.selectedPractice = undefined;
        },
    },
});

export const { viewPractice, closePractice } = practiceSlice.actions;
export const practiceReducer = practiceSlice.reducer;
