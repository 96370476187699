import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { baseName, catalogGateway } from './baseThunkInfo';
import { CartState } from '../cart.state';

import { translate } from '../../../../../adapters/secondary/services/translation.service';
import { Services } from '../../../../../adapters/secondary/services/Services.service';
import { Toaster } from '../../../../../utils/toaster';
import { Practice } from '../../../../../domain/practice/practice';
import { ID } from '../../../../../domain/shared';

let toaster: Toaster | null = null;
export const exportPersonalisedCatalog = createAsyncThunk(
    `${baseName}/exportPersonalisedCatalog`,
    async ({ practices, userId }: { practices: Practice[]; userId: ID }) => {
        toaster = Toaster.loading(translate('EXPORT_LOADING_TOASTER'));
        return catalogGateway.exportPersonalisedCatalogWithPracticeIds(practices.map(({ _id }) => _id), userId);
    },
);

export const buildExportPersonalisedCatalogReducers = (builder: ActionReducerMapBuilder<CartState>) => {
    builder.addCase(exportPersonalisedCatalog.fulfilled, (state, action) => {
        toaster?.updateSuccess(translate('EXPORT_SUCCESS_TOASTER'));

        Services.instance().catalog.exportCatalog(action.payload);
    });

    builder.addCase(exportPersonalisedCatalog.rejected, () => {
        toaster?.updateError(translate('EXPORT_FAILURE_TOASTER', { name: 'Custom' }));
    });
};
