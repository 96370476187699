import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../corelogic/redux/slices/user/user.selector';
//import { useAuth } from '@frontegg/react-hooks';
import { useAuthInfo } from '@propelauth/react';

import { Comment } from '../../../../../../domain/practice/Comment';
import { GATEWAYS } from '../../../../../../corelogic/gateways/gatewaysUtils';
import { ID } from '../../../../../../domain/shared';
import { Toaster } from '../../../../../../utils/toaster';
import { translate } from '../../../../../secondary/services/translation.service';

export function usePracticeComments(
    comments: Comment[],
    practiceId: ID,
    setCommentNumber: (commentNumber: number) => void,
) {
    const [commentState, setCommentState] = useState(comments.sort((a, b) => b.timestamp - a.timestamp));
    const user = useSelector(selectUser);
    //const { isAuthenticated } = useAuth();
    const authInfo = useAuthInfo();
    const isAuthenticated = authInfo.isLoggedIn;

    const [isCommentAreaOpen, setIsCommentAreaOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const commentsGateway = GATEWAYS.getCommentsGateway();

    const newCommentRef = React.createRef<HTMLTextAreaElement>();

    const addComment = () => {
        if (!isAuthenticated) {
            setIsOpen(true);
            return;
        }
        const value = newCommentRef.current?.value;
        if (!value) return;
        const newComment: Comment = {
            comment: value,
            commenterName: user?.name ?? '',
            commenterId: user?._id ?? '',
            timestamp: Date.now(),
        };
        const oldCommentNumber = commentState.length;
        commentState.push(newComment);
        setCommentNumber(oldCommentNumber + 1);
        setCommentState(commentState.sort((a, b) => b.timestamp - a.timestamp));
        commentsGateway
            .addComment(newComment, practiceId)
            .then()
            .catch(() => {
                const oldCommentState = commentState.filter((comment) => comment.timestamp !== newComment.timestamp);
                setCommentState(oldCommentState.sort((a, b) => b.timestamp - a.timestamp));
                setCommentNumber(oldCommentNumber);
                Toaster.error(translate('ADD_COMMENT_ERROR'));
            });
        setIsCommentAreaOpen(false);
    };

    const openCommentArea = () => {
        if (!isAuthenticated) {
            setIsOpen(true);
            return;
        }
        setIsCommentAreaOpen(true);
    };

    const onPracticeChangeEffect = () => {
        commentsGateway
            .getComments(practiceId)
            .then((comments) => {
                setCommentState(comments);
                setCommentNumber(comments.length);
            })
            .catch(() => Toaster.error(translate('GET_COMMENTS_ERROR')));
    };

    const deleteComment = (comment: Comment) => {
        commentsGateway
            .deleteComment(comment)
            .then(() => {
                setCommentNumber(commentState.length - 1);
                setCommentState(commentState.filter((c) => comment !== c));
            })
            .catch(() => {
                Toaster.error(translate('COMMENT_DELETE_ERROR'));
            });
    };

    return {
        commentState,
        isCommentAreaOpen,
        openCommentArea,
        setIsCommentAreaOpen,
        addComment,
        isOpen,
        setIsOpen,
        newCommentRef,
        onPracticeChangeEffect,
        deleteComment,
    };
}
