import React, { useEffect } from 'react';
import { LineWidget } from 'codemirror';

import './codeViewer.scss';
import { useCodeViewer } from './codeViewer.logic';

import { IExampleWidget } from './exampleWidget/exampleWidget';

import { Example } from '../../../../domain/practice/example';
import { Practice } from '../../../../domain/practice/practice';

export type ICodeViewerProps = {
    example: Example;
    practice: Practice;
    setWidget: (widget: LineWidget) => void;
};

export const ICodeViewer: React.FC<ICodeViewerProps> = ({ example, practice, setWidget }) => {
    const { codeMirrorRef, editor, addExample, editorEffect, referenceEffect } = useCodeViewer(
        <IExampleWidget practice={practice} example={example} />,
        setWidget,
        example,
    );

    useEffect(editorEffect, [example]);
    useEffect(referenceEffect, [codeMirrorRef]);
    useEffect(addExample, [editor, example]);

    return (
        <div className="code-viewer">
            <div className="code-mirror" ref={codeMirrorRef} />
        </div>
    );
};
