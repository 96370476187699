import { Practice } from '../../../../domain/practice/practice';
import { Catalog } from '../../../../domain/catalog/catalog';
import { getState } from '../../../redux/store';

export function findCatalogFromPractice(practice: Practice): Catalog | undefined {
    const catalogs = getState().list.catalogs;
    return catalogs.find((catalog) => {
        const catalogPractice = catalog.practices.find(({ _id }) => practice._id === _id);
        return catalogPractice !== undefined;
    });
}
