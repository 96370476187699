import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { AuthProvider } from '@propelauth/react';
import { ThemeProvider } from '@mui/material';
import { darkTheme } from './utils/materialTheme';
import App from './App';
import { Env, getEnv } from './utils/env';
import { getStore } from './corelogic/redux/store';
import './utils/codeMirrorUtils';

if (getEnv(Env.NODE_ENV) === 'PRODUCTION') {
    Sentry.init({ dsn: getEnv(Env.REACT_APP_SENTRY_DSN) });
}

const store = getStore();

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Provider store={store}>
                <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
                    <ThemeProvider theme={darkTheme}>
                        <App />
                    </ThemeProvider>
                </AuthProvider>,
            </Provider>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
