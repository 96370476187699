import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { baseName, userCartGateway } from './baseThunkInfo';
import { ID } from '../../../../../domain/shared';
import { CartState } from '../cart.state';
import { Toaster } from '../../../../../utils/toaster';
import { translate } from '../../../../../adapters/secondary/services/translation.service';

export const loadCartFromDatabaseAction = createAsyncThunk(
    `${baseName}/LoadCartFromDatabaseAction`,
    async (userId: ID) => {
        return await userCartGateway.getCartForUser(userId);
    },
);

export function buildLoadCartFromDatabaseActionReducers(builder: ActionReducerMapBuilder<CartState>) {
    builder.addCase(loadCartFromDatabaseAction.fulfilled, (state, action) => {
        state.value = action.payload;
    });

    builder.addCase(loadCartFromDatabaseAction.rejected, () => {
        Toaster.error(translate('LOAD_CART_FROM_DATABASE_ERROR'));
    });
}
