import { APIService } from '../../adapters/secondary/services/API.service';

type Parameters = string[] | string | undefined;

export abstract class APIGateway {
    constructor(protected readonly baseURL: string, protected readonly apiService: APIService) {}

    protected apiGet<ReturnType>(params: Parameters = undefined, URLComplement = ''): Promise<ReturnType> {
        const formattedURL = this.formatURL(params, URLComplement);
        return this.apiService.get(formattedURL);
    }

    protected apiPost<BodyType, ReturnType>(
        body: BodyType,
        URLComplement = '',
        params: Parameters = undefined,
    ): Promise<ReturnType> {
        const formattedURL = this.formatURL(params, URLComplement);
        return this.apiService.post(formattedURL, body);
    }

    private formatURL(params: Parameters, URLComplement: string): string {
        const formattedURLComplement = APIGateway.formatURLComplement(URLComplement);
        const formattedParams = APIGateway.formatParams(params);
        return `${this.baseURL}${formattedURLComplement}/${formattedParams}`;
    }

    private static formatURLComplement(urlComplement: string): string {
        if (urlComplement.length === 0) {
            return '';
        }
        return `/${urlComplement}`;
    }

    private static formatParams(params: Parameters): string {
        if (params === undefined) {
            return '';
        } else if (typeof params === 'string') {
            return params;
        } else {
            return params.join('/');
        }
    }
}
