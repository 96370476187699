import { CommentsGateway } from '../../../corelogic/gateways/CommentsGateway.interface';
import { ID } from '../../../domain/shared';
import { Comment } from '../../../domain/practice/Comment';
import { APIGateway } from '../../../utils/abstracted/APIGateway';
import { APIService } from '../services/API.service';

export class CommentsAPIGateway extends APIGateway implements CommentsGateway {
    constructor(api: APIService) {
        super('comment', api);
    }
    async addComment(comment: Comment, practiceId: ID): Promise<void> {
        const commentId = await this.apiPost<{ comment: Comment; practiceId: ID }, ID>({ comment, practiceId });
        if (commentId) {
            comment._id = commentId;
        }
    }

    async getComments(practiceId: ID): Promise<Comment[]> {
        return this.apiGet(practiceId);
    }

    async deleteComment(comment: Comment): Promise<void> {
        if (!comment._id) {
            throw new Error('No id on comment');
        }
        return this.apiService.delete(`${this.baseURL}/${comment._id}`);
    }
}
