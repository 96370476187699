import { Catalog } from '../../../../domain/catalog/catalog';
import { Practice } from '../../../../domain/practice/practice';

import { setCartInDatabase } from '../persistentCart/setCartInDatabase';
import { getDispatch } from '../../../redux/store';
import { addCatalogToCartAction, addPracticeToCartAction } from '../../../redux/slices/cart/cart.slice';

export async function addCatalogToCart(catalog: Catalog) {
    const dispatch = getDispatch();
    const dispatchedAction = await dispatch(addCatalogToCartAction(catalog));
    await setCartInDatabase();
    return dispatchedAction;
}

export async function addPracticeToCart(practice: Practice) {
    const dispatch = getDispatch();
    await dispatch(addPracticeToCartAction(practice));
    await setCartInDatabase();
}
